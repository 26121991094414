import { useNavigate } from "react-router-dom";
import "./logout.css";
import warringIcon from "../../assets/images/warning-icon.webp";

function Logout() {
  const navigate = useNavigate();
  const logoutFunc = () => {
    localStorage.removeItem("brand");
    navigate("/login");
  };

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var labelSmoue;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      break;
    case DOMAINS.SMOUE:
      labelSmoue = true;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      atharWebsite = true;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      millionWebsite = true;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      btnDarb = false;
      labelSmoue = false;
      millionWebsite = false;
      takWebsite = false;
      break;
  }
  return (
    <div className="overlay">
      <div
        className="popup popup-logout"
        style={
          labelSmoue
            ? { backgroundColor: "rgba(255,255,255)" }
            : millionWebsite || sharkWebsite
            ? { backgroundColor: "#1F1F1F" }
            : takWebsite || transWebsite || fadaaWebsite
            ? { backgroundColor: "#1F1F1F", border: "1px solid white" }
            : atharWebsite
            ? { backgroundColor: "#F1F1F1", borderRadius: "0" }
            : {}
        }
      >
        <div className="warning-icon">
          <img src={warringIcon} alt="icon" />
        </div>

        <h2
          style={{
            color: sharkWebsite ? "#FA4B52" : fadaaWebsite ? "white" : "",
          }}
        >
          هل انت متأكد
        </h2>
        <h5
          style={{
            color:
              takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                ? "white"
                : atharWebsite
                ? "black"
                : "",
          }}
        >
          من تسجيل الخروج
        </h5>

        <div className="actions-btn">
          <button
            className="btn btn-yes"
            onClick={() => {
              logoutFunc();
            }}
            style={
              btnDarb
                ? {
                    background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                    color: "black",
                    border: "none",
                  }
                : labelSmoue
                ? {
                    background: `linear-gradient(to right, rgb(219, 55, 55), rgb(0, 39, 98))`,
                    color: "white",
                    borderRadius: "10px",
                    border: "none",
                  }
                : millionWebsite
                ? { color: "black" }
                : takWebsite
                ? {
                    backgroundColor: "#00F800",
                    borderRadius: "55px",
                    boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                    color: "black",
                  }
                : transWebsite
                ? {
                    borderRadius: "8px",
                    color: "black",
                  }
                : sharkWebsite
                ? {
                    backgroundColor: "#FA4B52",
                    color: "white",
                    border: "1px solid #FBFA2D",
                  }
                : atharWebsite
                ? {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "55px",
                    boxShadow: "#F15A3A 4px 5px 1px 0px",
                    color: "black",
                  }
                : fadaaWebsite
                ? {
                    backgroundColor: "#F5ED0C",
                    color: "black",
                  }
                : {}
            }
          >
            نعم
          </button>
          <button
            className="btn btn-no"
            onClick={() => navigate(-1)}
            style={
              btnDarb
                ? {
                    border: "1px solid #11D4D1",
                  }
                : labelSmoue
                ? { borderRadius: "10px" }
                : millionWebsite
                ? { borderColor: "white", color: "white" }
                : takWebsite
                ? {
                    backgroundColor: "transparent",
                    borderRadius: "55px",
                    color: "white",
                    border: "1px solid #FF0593",
                  }
                : transWebsite
                ? {
                    borderRadius: "8px",
                    color: "white",
                    border: "1px solid #02C6C6",
                  }
                : sharkWebsite
                ? {
                    backgroundColor: "transparent",
                    color: "white",
                    border: "1px solid #FBFA2D",
                  }
                : atharWebsite
                ? {
                    backgroundColor: "transparent",
                    borderRadius: "55px",
                    color: "black",
                    border: "1px solid #F15A3A",
                  }
                : fadaaWebsite
                ? {
                    background: "transparent",
                    color: "white",
                    border: "1px solid #F5ED0C",
                  }
                : {}
            }
          >
            لا
          </button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
