import React, { useEffect, useState } from "react";
import "./singleSocial.css";
import face from "../../assets/images/facebook.webp";
import insta from "../../assets/images/insta.webp";
import youtube from "../../assets/images/youtube.webp";
import twitter from "../../assets/images/twitter.webp";
import tiktok from "../../assets/images/tiktok.webp";
import snap from "../../assets/images/snap.webp";
import stats1 from "../../assets/images/stats1.webp";
import stats2 from "../../assets/images/stats2.webp";
import stats3 from "../../assets/images/stats3.webp";
import stats4 from "../../assets/images/stats4.webp";
import stats5 from "../../assets/images/stats5.webp";
import stats6 from "../../assets/images/stats6.webp";
import stats7 from "../../assets/images/stats7.webp";
import stats8 from "../../assets/images/stats8.webp";
import stats9 from "../../assets/images/stats9.webp";
import stats10 from "../../assets/images/stats10.webp";
import stats1Million from "../../assets/images/stats1-million.png";
import stats2Million from "../../assets/images/stats2-million.png";
import stats3Million from "../../assets/images/stats3-million.png";
import stats4Million from "../../assets/images/stats4-million.png";
import stats5Million from "../../assets/images/stats5-million.png";
import stats6Million from "../../assets/images/stats6-million.png";
import stats7Million from "../../assets/images/stats7-million.png";
import stats8Million from "../../assets/images/stats8-million.png";
import stats9Million from "../../assets/images/stats9-million.png";
import stats10Million from "../../assets/images/stats10-million.png";
import earth from "../../assets/images/earth.webp";
import Chart2 from "../chart2/Chart2";
import Chart3 from "../chart3/Chart3";
import Chart1 from "../chart1/Chart1";
import Bar from "../bar/bar";
import authService from "../../services/auth-services";
import { useNavigate, useParams } from "react-router-dom";

const SingleSocial = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const platformIcons = {
    Facebook: face,
    facebook: face,
    face: face,
    Face: face,
    Twitter: twitter,
    twitter: twitter,
    Instagram: insta,
    instagram: insta,
    insta: insta,
    Insta: insta,
    Tiktok: tiktok,
    tiktok: tiktok,
    Snapchat: snap,
    snap: snap,
    Snap: snap,
    snapchat: snap,
    Youtube: youtube,
    youtube: youtube,
  };
  const id = useParams();
  let standered = "facebook";
  const [influencer, setinfluencer] = useState();
  const influencerInfo = () => {
    authService
      .influencerInformation(id["influencer_id"])
      .then((response) => {
        setinfluencer(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem("brand");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (influencer && influencer.influencer.social_media.length > 0) {
      setchoosen(influencer.influencer.social_media[0].platform);
    }
  }, [influencer]);
  useEffect(() => {
    influencerInfo();
  }, []);
  const [choosen, setchoosen] = useState(standered);

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFO: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var khatwaBox;
  var infu;
  var millionWebsite;
  var statsWebsites1;
  var statsWebsites2;
  var statsWebsites3;
  var statsWebsites4;
  var statsWebsites5;
  var statsWebsites6;
  var statsWebsites7;
  var statsWebsites8;
  var statsWebsites9;
  var statsWebsites10;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var roquaWebsite;
  var fadaaWebsite;
  var basmaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      khatwaBox = false;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      infu = false;
      khatwaBox = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      atharWebsite = true;
      break;

    case DOMAINS.KHATWA:
      btnDarb = false;
      khatwaBox = true;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.INFO:
      btnDarb = false;
      khatwaBox = false;
      infu = true;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      millionWebsite = true;
      statsWebsites1 = stats1Million;
      statsWebsites2 = stats2Million;
      statsWebsites3 = stats3Million;
      statsWebsites4 = stats4Million;
      statsWebsites5 = stats5Million;
      statsWebsites6 = stats6Million;
      statsWebsites7 = stats7Million;
      statsWebsites8 = stats8Million;
      statsWebsites9 = stats9Million;
      statsWebsites10 = stats10Million;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = true;
      statsWebsites1 = stats1Million;
      statsWebsites2 = stats2Million;
      statsWebsites3 = stats3Million;
      statsWebsites4 = stats4Million;
      statsWebsites5 = stats5Million;
      statsWebsites6 = stats6Million;
      statsWebsites7 = stats7Million;
      statsWebsites8 = stats8Million;
      statsWebsites9 = stats9Million;
      statsWebsites10 = stats10Million;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = false;
      transWebsite = true;
      statsWebsites1 = stats1Million;
      statsWebsites2 = stats2Million;
      statsWebsites3 = stats3Million;
      statsWebsites4 = stats4Million;
      statsWebsites5 = stats5Million;
      statsWebsites6 = stats6Million;
      statsWebsites7 = stats7Million;
      statsWebsites8 = stats8Million;
      statsWebsites9 = stats9Million;
      statsWebsites10 = stats10Million;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      statsWebsites1 = stats1Million;
      statsWebsites2 = stats2Million;
      statsWebsites3 = stats3Million;
      statsWebsites4 = stats4Million;
      statsWebsites5 = stats5Million;
      statsWebsites6 = stats6Million;
      statsWebsites7 = stats7Million;
      statsWebsites8 = stats8Million;
      statsWebsites9 = stats9Million;
      statsWebsites10 = stats10Million;
      break;

    case DOMAINS.Roqua:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = true;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = false;
      fadaaWebsite = true;
      statsWebsites1 = stats1Million;
      statsWebsites2 = stats2Million;
      statsWebsites3 = stats3Million;
      statsWebsites4 = stats4Million;
      statsWebsites5 = stats5Million;
      statsWebsites6 = stats6Million;
      statsWebsites7 = stats7Million;
      statsWebsites8 = stats8Million;
      statsWebsites9 = stats9Million;
      statsWebsites10 = stats10Million;
      break;

    case DOMAINS.BASMA:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = false;
      fadaaWebsite = false;
      basmaWebsite = true;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      break;

    default:
      btnDarb = false;
      khatwaBox = false;
      infu = false;
      statsWebsites1 = stats1;
      statsWebsites2 = stats2;
      statsWebsites3 = stats3;
      statsWebsites4 = stats4;
      statsWebsites5 = stats5;
      statsWebsites6 = stats6;
      statsWebsites7 = stats7;
      statsWebsites8 = stats8;
      statsWebsites9 = stats9;
      statsWebsites10 = stats10;
      millionWebsite = false;
      takWebsite = false;
      break;
  }

  return (
    <div
      className="singleSocial pt-5"
      style={{
        backgroundColor:
          millionWebsite || takWebsite || sharkWebsite
            ? "black"
            : transWebsite
            ? "#0F0F0F"
            : "",
      }}
    >
      <div className="icons ">
        <div className="container">
          <div className="row d-flex justify-content-evenly">
            {influencer &&
              influencer.influencer.social_media.map((item, i) => {
                return (
                  <div className="col-lg-2 col-4 mb-3" key={i}>
                    <div
                      className={
                        item.platform === choosen
                          ? `image   ${
                              btnDarb
                                ? `darbActiveIcon darb-image`
                                : `activeIcon websites-image`
                            }`
                          : `image ${basmaWebsite ? "basmaIcon" : ""}`
                      }
                      onClick={() => setchoosen(item.platform)}
                      style={atharWebsite ? { borderRadius: "0" } : {}}
                    >
                      <img src={platformIcons[item.platform]} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {influencer &&
        influencer.influencer.social_media
          .filter((item) => item.platform === choosen)
          ?.map((item, i) => {
            // const countrieskeys=Object.keys(item.followers_countries)
            let data = [];
            let segment = [];
            let education = [];
            let age = [];
            function generateRandomColor() {
              const getRandomColor = () => {
                const r = Math.floor(Math.random() * 255);
                const g = Math.floor(Math.random() * 255);
                const b = Math.floor(Math.random() * 255);

                const rHex = r.toString(16).padStart(2, "0");
                const gHex = g.toString(16).padStart(2, "0");
                const bHex = b.toString(16).padStart(2, "0");

                return `#${rHex}${gHex}${bHex}`;
              };

              const isLightColor = (color) => {
                const r = parseInt(color.substring(1, 3), 16);
                const g = parseInt(color.substring(3, 5), 16);
                const b = parseInt(color.substring(5, 7), 16);

                const brightness = (r * 299 + g * 587 + b * 114) / 1000;

                return brightness >= 150;
              };

              let color = getRandomColor();
              while (isLightColor(color)) {
                color = getRandomColor();
              }

              return color;
            }
            item.followers_age !== null &&
              Object.keys(item.followers_age).forEach((key) => {
                age.push({
                  country: key,
                  key: +item?.followers_age[key].slice(0, -1),
                  ageColor: generateRandomColor(),
                  color: generateRandomColor(),
                });
              });
            item.followers_languages !== null &&
              Object.keys(item.followers_languages).forEach((key) => {
                data.push({
                  id: key,
                  label: key,
                  value: +item?.followers_languages[key].slice(0, -1),
                  color: generateRandomColor(),
                });
              });
            item.followers_education !== null &&
              Object.keys(item.followers_education).forEach((key) => {
                education.push({
                  country: key,
                  key: +item?.followers_education[key].slice(0, -1),
                  ageColor: generateRandomColor(),
                  color: generateRandomColor(),
                });
              });
            item.followers_segmentation !== null &&
              Object.keys(item.followers_segmentation).forEach((key) => {
                segment.push({
                  id: key,
                  label: key,
                  value: +item?.followers_segmentation[key].slice(0, -1),
                  color: generateRandomColor(),
                });
              });
            let interests =
              item.followers_interests !== null &&
              Object.keys(item.followers_interests);
            return (
              <div className="allStats m-sm-0 p-3 infl-container" key={i}>
                <div className="container">
                  <div className="row gap-4 top mb-4">
                    <div
                      className={
                        btnDarb
                          ? "col d-flex gap-5 info darb-info"
                          : khatwaBox
                          ? "col d-flex gap-5 info khatwa-info"
                          : infu
                          ? "col d-flex gap-5 info websites-info border-infu"
                          : "col d-flex gap-5 info websites-info"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="image">
                        <img src={statsWebsites1} alt="" />
                      </div>
                      <div className="text">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "black"
                                : "",
                          }}
                        >
                          {item.engagement_rate}
                        </p>
                        <span
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              fadaaWebsite
                                ? "white"
                                : sharkWebsite
                                ? "#B559E8"
                                : atharWebsite
                                ? "#F15A3A"
                                : "",
                          }}
                        >
                          معدل المشاركة
                        </span>
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col d-flex gap-5 info darb-info"
                          : khatwaBox
                          ? "col d-flex gap-5 info khatwa-info"
                          : infu
                          ? "col d-flex gap-5 info websites-info border-infu"
                          : "col d-flex gap-5 info websites-info"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="image">
                        <img src={statsWebsites2} alt="" />
                      </div>
                      <div className="text">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "black"
                                : "",
                          }}
                        >
                          {item.followers}
                        </p>
                        <span
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              fadaaWebsite
                                ? "white"
                                : sharkWebsite
                                ? "#B559E8"
                                : atharWebsite
                                ? "#F15A3A"
                                : "",
                          }}
                        >
                          عدد المتابعين
                        </span>
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col d-flex gap-5 info darb-info"
                          : khatwaBox
                          ? "col d-flex gap-5 info khatwa-info"
                          : infu
                          ? "col d-flex gap-5 info websites-info border-infu"
                          : "col d-flex gap-5 info websites-info"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="image">
                        <img src={statsWebsites3} alt="" />
                      </div>
                      <div className="text">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "black"
                                : "",
                          }}
                        >
                          {item.comments_to_likes_ratio}
                        </p>
                        <span
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              fadaaWebsite
                                ? "white"
                                : sharkWebsite
                                ? "#B559E8"
                                : atharWebsite
                                ? "#F15A3A"
                                : "",
                          }}
                        >
                          نسبة التعليقات الي الاعجبات
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row gap-4 mb-4 middle-1">
                    <div
                      className={
                        btnDarb
                          ? "col darb-followers followers d-flex flex-column justify-content-between"
                          : khatwaBox
                          ? "col khatwa-followers followers d-flex flex-column justify-content-between"
                          : infu
                          ? "col khatwa-followers followers d-flex flex-column justify-content-between border-infu"
                          : "col websites-followers followers d-flex flex-column justify-content-between"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text">
                          <p
                            style={{
                              color:
                                millionWebsite ||
                                takWebsite ||
                                transWebsite ||
                                sharkWebsite ||
                                fadaaWebsite
                                  ? "white"
                                  : atharWebsite
                                  ? "rgba(0, 0, 0, 0.57)"
                                  : roquaWebsite
                                  ? "rgba(2, 36, 68, 0.57)"
                                  : "",
                            }}
                          >
                            المتابعين الاكثر سننا
                          </p>
                          {/* <h2>[{maxAge(item.followers_age)}]</h2> */}
                        </div>
                        <div className="image">
                          <img src={statsWebsites4} alt="" />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center">
                          {item.followers_age && (
                            <Chart2 data={age} width="180px" height="120px" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col darb-followers followers d-flex flex-column justify-content-between"
                          : khatwaBox
                          ? "col khatwa-followers followers d-flex flex-column justify-content-between"
                          : infu
                          ? "col khatwa-followers followers d-flex flex-column justify-content-between border-infu"
                          : "col websites-followers followers d-flex flex-column justify-content-between"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="text">
                          <p
                            style={{
                              color:
                                millionWebsite ||
                                takWebsite ||
                                transWebsite ||
                                sharkWebsite ||
                                fadaaWebsite
                                  ? "white"
                                  : atharWebsite
                                  ? "rgba(0, 0, 0, 0.57)"
                                  : roquaWebsite
                                  ? "rgba(2, 36, 68, 0.57)"
                                  : "",
                            }}
                          >
                            المتابعين الاكثر نوعا
                          </p>
                          <h2
                            style={{
                              color:
                                millionWebsite ||
                                takWebsite ||
                                transWebsite ||
                                sharkWebsite ||
                                fadaaWebsite
                                  ? "white"
                                  : "",
                            }}
                          >
                            {item?.followers_gender?.ذكور >
                            item?.followers_gender?.اناث
                              ? "ذكور"
                              : "اناث"}
                          </h2>
                        </div>
                        <div className="image">
                          <img src={statsWebsites5} alt="" />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Chart3
                          y1={parseInt(
                            item?.followers_gender?.اناث?.slice(0, -1)
                          )}
                          y2={parseInt(
                            item?.followers_gender?.ذكور?.slice(0, -1)
                          )}
                        />
                        <Chart3
                          y1={parseInt(
                            item?.followers_gender?.ذكور?.slice(0, -1)
                          )}
                          y2={parseInt(
                            item?.followers_gender?.اناث?.slice(0, -1)
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col darb-followers followers"
                          : khatwaBox
                          ? "col khatwa-followers followers"
                          : infu
                          ? "col khatwa-followers followers border-infu"
                          : "col websites-followers followers"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "rgba(0, 0, 0, 0.57)"
                                : roquaWebsite
                                ? "rgba(2, 36, 68, 0.57)"
                                : "",
                          }}
                        >
                          الدول الاعلي
                        </p>
                        <h2
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          {item?.highest_country}
                        </h2>
                      </div>
                      <div className="image">
                        <img src={earth} alt="" />
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col darb-followers followers"
                          : khatwaBox
                          ? "col khatwa-followers followers"
                          : infu
                          ? "col khatwa-followers followers border-infu"
                          : "col websites-followers followers"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <p
                        style={{
                          color:
                            millionWebsite ||
                            takWebsite ||
                            transWebsite ||
                            sharkWebsite ||
                            fadaaWebsite
                              ? "white"
                              : atharWebsite
                              ? "rgba(0, 0, 0, 0.57)"
                              : roquaWebsite
                              ? "rgba(2, 36, 68, 0.57)"
                              : "",
                        }}
                      >
                        جودة المتابعين
                      </p>
                      <h2
                        style={{
                          color:
                            millionWebsite ||
                            takWebsite ||
                            transWebsite ||
                            sharkWebsite ||
                            fadaaWebsite
                              ? "white"
                              : "",
                        }}
                      >
                        {item?.followers_quality}
                      </h2>
                    </div>
                  </div>
                  <div className="row middle-2 gap-4 mb-4">
                    <div
                      className={
                        btnDarb
                          ? "col-lg col-12 darb-location location d-flex flex-column gap-4 p-3 p-md-4"
                          : khatwaBox
                          ? "col-lg col-12 khatwa-location location d-flex flex-column gap-4 p-3 p-md-4"
                          : infu
                          ? "col-lg col-12 khatwa-location location d-flex flex-column gap-4 p-3 p-md-4 border-infu"
                          : "col-lg col-12 websites-location location d-flex flex-column gap-4 p-3 p-md-4"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="head d-flex justify-content-between align-items-center">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "rgba(0, 0, 0, 0.57)"
                                : roquaWebsite
                                ? "rgba(2, 36, 68, 0.57)"
                                : "",
                          }}
                        >
                          البلاد
                        </p>
                        <div className="image">
                          <img src={statsWebsites7} alt="" />
                        </div>
                      </div>
                      {item.followers_countries &&
                        Object.entries(item?.followers_countries).map(
                          (country, i) => (
                            <Bar
                              color={generateRandomColor()}
                              width={country[1]}
                              text={country[0]}
                              key={i}
                            />
                          )
                        )}
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col-lg col-12 darb-location location d-flex flex-column gap-4 p-3 p-md-4"
                          : khatwaBox
                          ? "col-lg col-12 khatwa-location location d-flex flex-column gap-4 p-3 p-md-4"
                          : infu
                          ? "col-lg col-12 khatwa-location location d-flex flex-column gap-4 p-3 p-md-4 border-infu"
                          : "col-lg col-12 websites-location location d-flex flex-column gap-4 p-3 p-md-4"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="one d-flex justify-content-between gap-3">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "rgba(0, 0, 0, 0.57)"
                                : roquaWebsite
                                ? "rgba(2, 36, 68, 0.57)"
                                : "",
                          }}
                        >
                          اللغات
                        </p>
                        <div className="image">
                          <img src={statsWebsites6} alt="" />
                        </div>
                      </div>
                      <div className="two d-flex flex-sm-row flex-column justify-content-between align-items-center gap-5">
                        <div className="legends gap-3 d-flex flex-row flex-wrap flex-sm-column justify-content-center">
                          {data &&
                            data.map((item) => (
                              <div
                                className="legend d-flex align-items-center gap-3"
                                key={item.id}
                              >
                                <div
                                  className="circle"
                                  style={{ backgroundColor: item.color }}
                                ></div>
                                <div className="text">
                                  <h2
                                    style={{
                                      color: atharWebsite ? "black" : "",
                                    }}
                                  >
                                    {item.value}%
                                  </h2>
                                  <span
                                    style={{
                                      color:
                                        millionWebsite ||
                                        takWebsite ||
                                        transWebsite ||
                                        sharkWebsite ||
                                        fadaaWebsite
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {item.id}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="chart1">
                          <Chart1
                            data={data}
                            text={`${data.length} لغات`}
                            innerRadius={0.6}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gap-4 pb-4 bottom">
                    <div className="col double gap-4">
                      {item.followers_segmentation && (
                        <div
                          className={
                            btnDarb
                              ? "row darb-location location mb-4 px-3 px-md-5 "
                              : khatwaBox
                              ? "row khatwa-location location mb-4 px-3 px-md-5"
                              : "row websites-location location mb-4 px-3 px-md-5 "
                          }
                          style={{
                            borderRadius: fadaaWebsite ? "0" : "",
                            backgroundColor: basmaWebsite ? "white" : "",
                            boxShadow: basmaWebsite
                              ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                              : "",
                          }}
                        >
                          <div>
                            <div className="one d-flex justify-content-between align-items-start gap-3">
                              <p
                                style={{
                                  color:
                                    millionWebsite ||
                                    takWebsite ||
                                    transWebsite ||
                                    sharkWebsite ||
                                    fadaaWebsite
                                      ? "white"
                                      : atharWebsite
                                      ? "rgba(0, 0, 0, 0.57)"
                                      : "",
                                }}
                              >
                                تصنيف المتابعين
                              </p>
                              <div className="image">
                                <img src={statsWebsites9} alt="" />
                              </div>
                            </div>
                            <div className="two d-flex flex-sm-row flex-column justify-content-between align-items-center gap-2">
                              <div className="legends gap-3 d-flex flex-row flex-wrap flex-sm-column justify-content-center">
                                {item?.followers_segmentation &&
                                  Object.entries(
                                    item?.followers_segmentation
                                  ).map((segment, i) => {
                                    return (
                                      <div
                                        className="legend d-flex align-items-center gap-3"
                                        key={i}
                                      >
                                        <div
                                          className="circle"
                                          style={{ backgroundColor: "#135DD7" }}
                                        ></div>
                                        <div className="text">
                                          <h2
                                            style={{
                                              color: atharWebsite
                                                ? "black"
                                                : "",
                                            }}
                                          >
                                            {segment[1]}
                                          </h2>
                                          <span
                                            style={{
                                              color:
                                                millionWebsite ||
                                                takWebsite ||
                                                transWebsite ||
                                                sharkWebsite ||
                                                fadaaWebsite
                                                  ? "white"
                                                  : "",
                                            }}
                                          >
                                            {segment[0]}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="">
                                <Chart1
                                  data={segment}
                                  text={`${segment.length} تصنيفات`}
                                  innerRadius={0.6}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row location ">
                        <div
                          className={
                            btnDarb
                              ? "col darb-location location d-flex flex-column justify-content-between gap-3"
                              : khatwaBox
                              ? "col khatwa-location location d-flex flex-column justify-content-between gap-3"
                              : infu
                              ? "col khatwa-location location d-flex flex-column justify-content-between gap-3 border-infu"
                              : "col websites-location location d-flex flex-column justify-content-between gap-3"
                          }
                          style={{
                            borderRadius: fadaaWebsite ? "0" : "",
                            backgroundColor: basmaWebsite ? "white" : "",
                            boxShadow: basmaWebsite
                              ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                              : "",
                          }}
                        >
                          <div className="one d-flex justify-content-between align-items-center gap-5 px-3">
                            <p
                              style={{
                                color:
                                  millionWebsite ||
                                  takWebsite ||
                                  transWebsite ||
                                  sharkWebsite ||
                                  fadaaWebsite
                                    ? "white"
                                    : atharWebsite
                                    ? "rgba(0, 0, 0, 0.57)"
                                    : "",
                              }}
                            >
                              تعليم المتابعين
                            </p>
                            <div className="image">
                              <img src={statsWebsites10} alt="" />
                            </div>
                          </div>
                          <div className="two d-flex flex-column flex-sm-row justify-content-between align-items-center gap-5 px-3">
                            <div className="legends d-flex flex-row flex-wrap justify-content-center flex-sm-column gap-5">
                              {education &&
                                education.map((item) => (
                                  <div
                                    className="legend d-flex align-items-center gap-3"
                                    key={item.id}
                                  >
                                    <div
                                      className="circle"
                                      style={{ backgroundColor: item.color }}
                                    ></div>
                                    <div className="text">
                                      <h2
                                        style={{
                                          color:
                                            millionWebsite ||
                                            takWebsite ||
                                            transWebsite ||
                                            sharkWebsite ||
                                            fadaaWebsite
                                              ? "white"
                                              : atharWebsite
                                              ? "black"
                                              : "",
                                        }}
                                      >
                                        {item.country}
                                      </h2>
                                      <span>{item.value}</span>
                                    </div>
                                  </div>
                                ))}
                            </div>

                            <div
                              className="chart"
                              style={{ height: "270px", width: "270px" }}
                            >
                              {item.followers_age && (
                                <Chart2
                                  data={education}
                                  width="300px"
                                  height="250px"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        btnDarb
                          ? "col darb-location location d-flex flex-column gap-4 p-5 p-sm-3"
                          : khatwaBox
                          ? "col khatwa-location location d-flex flex-column gap-4 p-5 p-sm-3"
                          : infu
                          ? "col khatwa-location location d-flex flex-column gap-4 p-5 p-sm-3 border-infu"
                          : "col websites-location location d-flex flex-column gap-4 p-5 p-sm-3"
                      }
                      style={{
                        borderRadius: fadaaWebsite ? "0" : "",
                        backgroundColor: basmaWebsite ? "white" : "",
                        boxShadow: basmaWebsite
                          ? "0px 4px 14px -3px rgba(46, 49, 111, 1)"
                          : "",
                      }}
                    >
                      <div className="head d-flex justify-content-between align-items-center">
                        <p
                          style={{
                            color:
                              millionWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "rgba(0, 0, 0, 0.57)"
                                : "",
                          }}
                        >
                          الاهتمامات
                        </p>
                        <div className="image">
                          <img src={statsWebsites8} alt="" />
                        </div>
                      </div>
                      <div className="bars d-flex flex-column justify-content-evenly">
                        {interests &&
                          interests.map((interests, i) => {
                            return (
                              <Bar
                                color={generateRandomColor()}
                                width={Object.values(
                                  item.followers_interests[interests]
                                ).join("")}
                                text={interests}
                                key={i}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default SingleSocial;
