import React from "react";
import "./bar.css";

const Bar = ({ color, width, text }) => {
  var subdomain = window.location.origin;
  var millionWebsite = false;
  var atharWebsite = false;
  if ((subdomain = "https://influencer.one1million.com")) {
    millionWebsite = true;
  }
  if ((subdomain = "https://influencer.atherr.com")) {
    atharWebsite = true;
  }

  return (
    <div
      className="bar d-flex flex-column gap-2"
      style={{ color: millionWebsite ? "white" : "" }}
    >
      <p>{text}</p>
      <div className="background">
        <div
          className="fill"
          style={{ backgroundColor: color, width: width }}
        ></div>
      </div>
    </div>
  );
};

export default Bar;
