import React, { useState, useEffect } from "react";
import "./whatWeOffer.css";
import Title from "../title/Title";
import Card from "../card/Card";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../../helpers";
import authService from "../../services/auth-services";

const WhatWeOffer = () => {
  const [topInfluencers, setTopInfluencers] = useState();
  const [topInfluencersUrl, setTopInfluencersUrl] = useState(
    getApiUrl("api/influencers/top/")
  );
  const navigate = useNavigate();
  const [loginToken, setLoginToken] = useState(false);
  const brand = JSON.parse(localStorage.getItem("brand"));
  useEffect(() => {
    setLoginToken(brand ? true : false);
  }, [brand]);

  const getTopInfluencers = () => {
    authService
      .topInfluencers(topInfluencersUrl)
      .then((response) => {
        setTopInfluencers(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem("brand");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    getTopInfluencers();
  }, [topInfluencersUrl]);

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      atharWebsite = true;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      millionWebsite = true;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.MSHOR:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = true;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      btnDarb = false;
      millionWebsite = false;
      break;
  }

  return (
    <div
      className="whatWeOffer pb-5"
      style={{
        backgroundColor:
          millionWebsite || takWebsite || transWebsite || sharkWebsite
            ? "black"
            : fadaaWebsite
            ? "#170E26"
            : "",
      }}
    >
      <div className="container">
        <Title
          color={
            takWebsite || transWebsite || sharkWebsite || fadaaWebsite
              ? "white"
              : atharWebsite || mshorWebsite
              ? "black"
              : "var(--primaryColor)"
          }
          text="ماذا نقدم ؟"
        />
        <div className="cards">
          <div className="container card-con">
            {topInfluencers &&
              topInfluencers.map((topInfluencer, index) => (
                <div className="" key={index}>
                  <Card
                    topInfluencers={topInfluencers[index]}
                    index={index}
                    getTopInfluencers={getTopInfluencers}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="btn-parent-more">
          <button
            style={
              btnDarb
                ? {
                    background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                    color: "white",
                    border: "none",
                  }
                : millionWebsite
                ? { color: "black" }
                : takWebsite
                ? {
                    backgroundColor: "#00F800",
                    borderRadius: "55px",
                    boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                    color: "black",
                  }
                : transWebsite
                ? {
                    backgroundColor: "white",
                    color: "black",
                  }
                : sharkWebsite
                ? {
                    backgroundColor: "#FA4B52",
                    color: "white",
                    border: "1px solid #FBFA2D",
                  }
                : atharWebsite
                ? {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "55px",
                    boxShadow: "#F15A3A 4px 5px 1px 0px",
                    color: "black",
                    border: "1px solid #00000014",
                  }
                : fadaaWebsite
                ? {
                    backgroundColor: "#F5ED0C",
                    color: "black",
                  }
                : {}
            }
            className="btn btn-show-more"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              if (loginToken) {
                navigate("/all-influencers");
              } else {
                navigate("/login");
              }
            }}
          >
            عرض المزيد
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
