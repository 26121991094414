import { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";

import NavBar from "../NabBar/NavBar";
import Footer from "../footer/Footer";
import "./profile-setting.css";
import smouInfluencerBg from "../../smou/smou-imgs/smou-influencer-bg.webp";
import SmouNavbar from "../../smou/smou-components/smou-navbar/SmouNavbar";
import SmouFooter from "../../smou/smou-footer/SmouFooter";
import InfuFooter from "../infu-footer/InfuFooter";

var subdomain = window.location.origin;
const DOMAINS = {
  AZZRK: "https://influencer.azzrk.com",
  ATHAR: "https://influencer.atherr.com",
  MARDOD: "https://influencer.marrdoud.com",
  WARAQA: "https://influencer.warrqa.com",
  DARB: "https://influencer.darbplatform.com",
  SMOUE: "https://influencer.sumoue.com",
  SAHM: "https://influencer.sahhm.com",
  KHATWA: "https://influencer.khatwaa.com",
  RAWNQ: "https://influencer.rawnkagency.com",
  BASMA: "https://influencer.basmahagency.com",
  INFU: "https://dashboard.infugram.com",
  MILLION: "https://influencer.one1million.com",
  TAK: "https://influencer.agencytak.com",
  TRANS: "https://influencer.transition-agency.com",
  SHARK: "https://influencer.shark-company.com",
  MSHOR: "https://influencer.mshourr.com",
  FADAA: "https://influencer.fadaaagency.com",
};

var labelSmoue;
var infuFoot;
var millionWebsite;
var takWebsite;
var transWebSite;
var sharkWebSite;
var atharWebSite;
var mshorWebSite;
var fadaaWebsite;

switch (subdomain) {
  case DOMAINS.SMOUE:
    labelSmoue = true;
    break;

  case DOMAINS.INFU:
    infuFoot = true;
    break;

  case DOMAINS.MILLION:
    millionWebsite = true;
    break;

  case DOMAINS.TAK:
    takWebsite = true;
    break;

  case DOMAINS.TRANS:
    transWebSite = true;
    break;

  case DOMAINS.SHARK:
    sharkWebSite = true;
    break;

  case DOMAINS.ATHAR:
    atharWebSite = true;
    break;

  case DOMAINS.MSHOR:
    mshorWebSite = true;
    break;

    case DOMAINS.FADAA:
      fadaaWebsite = true;
      break;

  default:
    labelSmoue = false;
    millionWebsite = false;
    takWebsite = false;
    transWebSite = false;
    break;
}
const renderFooter = () => {
  if (labelSmoue) {
    return <SmouFooter />;
  } else if (infuFoot) {
    return <InfuFooter />;
  } else {
    return <Footer />;
  }
};

function ProfileSetting() {
  useEffect(() => {
    renderFooter();
  }, []);
  return (
    <div
      style={{
        backgroundImage: labelSmoue ? `url(${smouInfluencerBg})` : "",
        backgroundSize: labelSmoue ? "cover" : "",
        backgroundColor:
          millionWebsite || takWebsite || sharkWebSite
            ? "black"
            : transWebSite
            ? "#0F0F0F"
            :fadaaWebsite?"#170E26" : "",
      }}
    >
      <div className="profile-settin-page container">
        {labelSmoue ? <SmouNavbar /> : <NavBar />}
        <h1
          style={
            labelSmoue || takWebsite || transWebSite || fadaaWebsite
              ? { color: "#fff" }
              : sharkWebSite
              ? { color: "#FA4B52" }
              : atharWebSite || mshorWebSite
              ? { color: "black" }
              : {}
          }
        >
          الحساب الشخصي
        </h1>
        <ul
          className={
            millionWebsite
              ? "components-list components-list-million"
              : takWebsite
              ? "components-list components-list-tak"
              : transWebSite
              ? "components-list components-list-trans"
              : sharkWebSite
              ? "components-list components-list-shark"
              : atharWebSite
              ? "components-list components-list-athar"
              :fadaaWebsite
              ? "components-list components-list-fadaa"
              : "components-list"
          }
        >
          <div
            style={
              labelSmoue
                ? {
                    backgroundColor: "rgba(255,255,255,50%)",
                    borderRadius: "10px",
                  }
                : millionWebsite || takWebsite || transWebSite || sharkWebSite || fadaaWebsite
                ? { backgroundColor: "#1F1F1F" }
                : {}
            }
          >
            <li>
              <NavLink
                to="/profile-setting/profile-info"
                style={{ color: millionWebsite ? "white" : "" }}
              >
                الملف
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile-setting/change-password"
                style={{ color: millionWebsite ? "white" : "" }}
              >
                كلمة المرور
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/profile-setting/logout"
                style={{ color: millionWebsite ? "white" : "" }}
              >
                تسجيل الخروج
              </NavLink>
            </li>
          </div>
        </ul>
        <Outlet />
      </div>
      {renderFooter()}
    </div>
  );
}

export default ProfileSetting;
