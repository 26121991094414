import React, { Fragment, useState } from "react";
import "./contact-info-register.css";
import PhoneInput from "react-phone-number-input";

const ContactInfoRegister = (props) => {
  const [mobile, setMobile] = useState(props.mobile);
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);
  const [email, setEmail] = useState(props.email);
  const [isValid, setIsValid] = useState(true);

  function handleInputChange(event) {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    setIsValid(validateEmail(inputEmail));
  }

  function handleBlur() {
    setIsValid(validateEmail(email));
  }

  function validateEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const [mobileToogelHint, setMobileToogelhint] = useState(false);
  const [focusMobile, setFocusMobile] = useState(false);
  const [whatsappToogelHint, setWhatsappToogelhint] = useState(false);
  const [focusWhatsapp, setFocusWhatsapp] = useState(false);
  const [emailToogelHint, setEmailToogelhint] = useState(false);
  const [focusEmail, setFocusEmail] = useState(false);
  const [emailTestToogelHint, setEmailTestToogelhint] = useState(false);

  //focus mobile
  const handelMobileFocus = () => {
    setFocusMobile(true);
    setMobileToogelhint(false);
    const icon = document.querySelectorAll(".PhoneInputInput");
    icon[0].classList.add("className", "toogle-dir");
  };
  //focus whatsapp
  const handelWhatsappFocus = () => {
    setFocusWhatsapp(true);
    setWhatsappToogelhint(false);
    const icon = document.querySelectorAll(".PhoneInputInput");
    icon[1].classList.add("className", "toogle-dir");
  };
  //focus email
  const handelEmailFocus = () => {
    setFocusEmail(true);
    setEmailToogelhint(false);
  };

  const handleNext = () => {
    //mobile
    if (!mobile) {
      setMobileToogelhint(true);
      setFocusMobile(false);
    }
    //whatsapp
    if (!whatsapp) {
      setWhatsappToogelhint(true);
      setFocusWhatsapp(false);
    }
    //email
    if (!email) {
      setEmailToogelhint(true);
      setFocusEmail(false);
    }

    if (mobile && whatsapp && email && !emailTestToogelHint) {
      props.setToogleBtnSocial(true);
      props.setToogleBtnContact(false);
      props.setMobile(mobile);
      props.setWhatsapp(whatsapp);
      props.setEmail(email);
      props.setTitleClass2(true);
      props.setCircleClass2(true);
      props.setBorderCircle2(false);
      props.setBorderCircle3(true);
    }
  };

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var labelSmoue;
  var inputMillion;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      break;

    case DOMAINS.SMOUE:
      labelSmoue = true;
      inputMillion = false;
      takWebsite = false;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      atharWebsite = true;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = true;
      takWebsite = false;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.MSHOR:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = true;
      break;

      case DOMAINS.FADAA:
        btnDarb = false;
        labelSmoue = false;
        inputMillion = false;
        takWebsite = false;
        transWebsite = false;
        sharkWebsite = false;
        mshorWebsite = false;
        fadaaWebsite = true;
        break;

    default:
      btnDarb = false;
      labelSmoue = false;
      inputMillion = false;
      takWebsite = false;
      sharkWebsite = false;
      break;
  }

  return (
    <Fragment>
      <div className="contact-info-register">
        <form>
          <div
            className={
              inputMillion
                ? "contact-info-form million-contact-info-form"
                : takWebsite
                ? "contact-info-form tak-contact-info-form"
                : transWebsite
                ? "contact-info-form trans-contact-info-form"
                : sharkWebsite
                ? "contact-info-form shark-contact-info-form"
                : atharWebsite
                ? "contact-info-form athar-contact-info-form"
                : fadaaWebsite
                ? "contact-info-form fadaa-contact-info-form"
                : "contact-info-form"
            }
          >
            <div className="form-sec">
              <label
                style={
                  labelSmoue || takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                    ? {
                        color: "white",
                      }
                    : atharWebsite || mshorWebsite
                    ? { color: "#000000" }
                    : {}
                }
              >
                رقم الهاتف
              </label>
              <PhoneInput
                style={
                  labelSmoue
                    ? {
                        opacity: ".6",
                      }
                    : {}
                }
                className="form-phone-input"
                international
                placeholder="ادخل رقم الهاتف"
                value={mobile}
                onFocus={handelMobileFocus}
                onChange={setMobile}
              />
              {mobileToogelHint && <span className=" spanhint">مطلوب</span>}
              {focusMobile && !mobile && (
                <span className=" spanhint">مطلوب</span>
              )}
            </div>
            <div className="form-sec">
              <label
                style={
                  labelSmoue || takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                    ? {
                        color: "white",
                      }
                    : atharWebsite || mshorWebsite
                    ? { color: "#000000" }
                    : {}
                }
              >
                الواتساب
              </label>
              <PhoneInput
                style={
                  labelSmoue
                    ? {
                        opacity: ".6",
                      }
                    : {}
                }
                className="form-phone-input"
                international
                placeholder="ادخل رقم الواتساب "
                value={whatsapp}
                onFocus={handelWhatsappFocus}
                onChange={setWhatsapp}
              />
              {whatsappToogelHint && <span className=" spanhint">مطلوب</span>}
              {focusWhatsapp && !whatsapp && (
                <span className=" spanhint">مطلوب</span>
              )}
            </div>
            <div className="form-sec">
              <label
                style={
                  labelSmoue || takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                    ? {
                        color: "white",
                      }
                    : atharWebsite || mshorWebsite
                    ? { color: "#000000" }
                    : {}
                }
              >
                البريد الإلكترونى
              </label>
              <input
                style={
                  labelSmoue
                    ? {
                        opacity: ".6",
                      }
                    : inputMillion
                    ? { background: "black", color: "white" }
                    : takWebsite
                    ? {
                        background: "rgba(31, 31, 31, 1)",
                        color: "white",
                        border: "1px solid rgb(255, 5, 147)",
                      }
                    : transWebsite
                    ? {
                        background: "rgba(31, 31, 31, 1)",
                        color: "white",
                        border: "1px solid #02C6C6",
                      }
                    : sharkWebsite
                    ? {
                        border: "1px solid #B559E8",
                        background: "#1F1F1F",
                        color: "white",
                      }
                    : atharWebsite
                    ? {
                        border: "1px solid #F15A3A",
                        background: "transparent",
                        color: "black",
                      }
                    :fadaaWebsite
                    ? {
                        border: "1px solid #7444BD",
                        background: "transparent",
                        color: "white",
                      }
                    : {}
                }
                type="email"
                placeholder="ادخل البريد الإلكترونى"
                value={email}
                onFocus={handelEmailFocus}
                onChange={handleInputChange}
                onBlur={handleBlur}
              />
              {focusEmail && !isValid && (
                <span className=" spanhint">يجب أن يكون بريد إلكترونى</span>
              )}
              {emailToogelHint && <span className=" spanhint">مطلوب</span>}
            </div>
          </div>
          <div className="register-btns">
            <div
              className="btn-next"
              onClick={handleNext}
              style={
                btnDarb
                  ? {
                      background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                      color: "black",
                      border: "none",
                    }
                  : labelSmoue
                  ? {
                      background: `linear-gradient(to right,#DB3737, transparent 65%), linear-gradient(to left, #002762, transparent 215%)`,
                      color: "white",
                      border: "none",
                    }
                  : inputMillion
                  ? { color: "black" }
                  : takWebsite
                  ? {
                      backgroundColor: "#00F800",
                      borderRadius: "55px",
                      boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                      color: "black",
                    }
                  : transWebsite
                  ? { color: "black" }
                  : sharkWebsite
                  ? {
                      backgroundColor: "#FA4B52",
                      color: "white",
                      border: "1px solid #FBFA2D",
                    }
                  : atharWebsite
                  ? {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "55px",
                      boxShadow: "#F15A3A 4px 5px 1px 0px",
                      color: "black",
                    }
                  :fadaaWebsite
                  ? {
                      background: "#F5ED0C",
                      color: "black",
                    }
                  : {}
              }
            >
              التالى
            </div>
            <div
              className="btn-back"
              onClick={() => {
                props.setToogleBtnProfile(true);
                props.setToogleBtnContact(false);
                props.setTitleClass1(false);
                props.setCircleClass1(false);
                props.setBorderCircle1(true);
                props.setBorderCircle2(false);
              }}
              style={
                btnDarb
                  ? {
                      background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                      color: "black",
                      border: "none",
                    }
                  : labelSmoue
                  ? {
                      background: "transparent",
                      color: "white",
                      border: "1px solid white",
                    }
                  : inputMillion
                  ? { color: "black" }
                  : takWebsite
                  ? {
                      backgroundColor: "transparent",
                      borderRadius: "55px",
                      color: "white",
                      border: "1px solid #FF0593",
                    }
                  : transWebsite
                  ? {
                      color: "white",
                      backgroundColor: "transparent",
                      border: "1px solid #02C6C6",
                    }
                  : sharkWebsite
                  ? {
                      backgroundColor: "transparent",
                      color: "white",
                      border: "1px solid #FBFA2D",
                    }
                  : atharWebsite
                  ? {
                      backgroundColor: "transparent",
                      borderRadius: "55px",
                      color: "black",
                      border: "1px solid #F15A3A",
                    }
                  : mshorWebsite
                  ? { color: "black", border: "1px solid #FF7222" }
                  :fadaaWebsite
                  ? {
                      background: "transparent",
                      color: "white",
                      border: "1px solid #F5ED0C",
                    }
                  : {}
              }
            >
              السابق
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ContactInfoRegister;
