import React, { Fragment, useState, useEffect } from "react";
import "./login-form.css";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/auth-services.js";
import LoadingButton from "../loading-button/LoadingButton";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

var subdomain = window.location.origin;
const DOMAINS = {
  AZZRK: "https://influencer.azzrk.com",
  ATHAR: "https://influencer.atherr.com",
  MARDOD: "https://influencer.marrdoud.com",
  WARAQA: "https://influencer.warrqa.com",
  DARB: "https://influencer.darbplatform.com",
  SMOUE: "https://influencer.sumoue.com",
  SAHM: "https://influencer.sahhm.com",
  KHATWA: "https://influencer.khatwaa.com",
  RAWNQ: "https://influencer.rawnkagency.com",
  BASMA: "https://influencer.basmahagency.com",
  INFO: "https://dashboard.infugram.com",
  MILLION: "https://influencer.one1million.com",
  TAK: "https://influencer.agencytak.com",
  TRANS: "https://influencer.transition-agency.com",
  SHARK: "https://influencer.shark-company.com",
  Roqua: "https://influencer.roaya-marketing.com",
  MSHOR: "https://influencer.mshourr.com",
  FADAA: "https://influencer.fadaaagency.com",
};

var createColor;
var btnDarb;
var labelSmoue;
var infuNav;
var inputMillion;
var takWebsite;
var transWebsite;
var sharkWebsite;
var atharWebsite;
var mshorWebsite;
var fadaaWebsite;

switch (subdomain) {
  case DOMAINS.AZZRK:
    createColor = "#022444";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.MARDOD:
    createColor = "#16EDB1";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.SMOUE:
    createColor = "white";
    btnDarb = false;
    labelSmoue = true;
    takWebsite = false;
    break;

  case DOMAINS.DARB:
    createColor = "#0C2241";
    btnDarb = true;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.WARAQA:
    createColor = "#FF6A00";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.ATHAR:
    createColor = "#000000";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    atharWebsite = true;
    break;

  case DOMAINS.SAHM:
    createColor = "#5E384A";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.KHATWA:
    createColor = "#231F20";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.RAWNQ:
    createColor = "#054448";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.BASMA:
    createColor = "#243F7A";
    btnDarb = false;
    labelSmoue = false;
    takWebsite = false;
    break;

  case DOMAINS.INFO:
    createColor = "#101B37";
    btnDarb = false;
    labelSmoue = false;
    infuNav = true;
    takWebsite = false;
    break;

  case DOMAINS.MILLION:
    createColor = "white";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = true;
    takWebsite = false;
    break;

  case DOMAINS.TAK:
    createColor = "white";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = true;
    break;

  case DOMAINS.TRANS:
    createColor = "white";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = false;
    transWebsite = true;
    break;

  case DOMAINS.SHARK:
    createColor = "white";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = true;
    break;

  case DOMAINS.Roqua:
    createColor = "#085D60";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    break;

  case DOMAINS.MSHOR:
    createColor = "#FF7222";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    mshorWebsite = true;
    break;

  case DOMAINS.FADAA:
    createColor = "white";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    mshorWebsite = false;
    fadaaWebsite = true;
    break;

  default:
    createColor = "#022444";
    btnDarb = false;
    labelSmoue = false;
    inputMillion = false;
    break;
}

const LoginForm = ({ qrRouting, setQrRouting, id, setId }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userToogelhint, setUserToogelhint] = useState(false);
  const [checkUser, setcheckUser] = useState("");
  const [focusUser, setfocusUser] = useState(false);
  const [passToogelhint, setpassToogelhint] = useState(false);
  const [checkpass, setcheckpass] = useState("");
  const [focuspass, setfocuspass] = useState(false);
  const [toogelaccount, settoggelaccount] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [tooglePassword, setTooglePassword] = useState(true);
  const [eyeAppear, setEyeAppear] = useState(false);

  const navigate = useNavigate();

  const handelpass = () => {
    setpassToogelhint(false);
    setfocuspass(true);
    setEyeAppear(true);
  };

  useEffect(() => {
    if (username) {
      setUserToogelhint(false);
    }
  }, [username]);
  useEffect(() => {
    setcheckpass(password.length >= 1 ? true : false);
  }, [password]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username) {
      setUserToogelhint(true);
    }
    if (!password) {
      setpassToogelhint(true);
      setfocuspass(false);
    }
    if (username && password) {
      try {
        setLoadingLogin(true);
        await authService.brandLogin(username, password).then(
          () => {
            if (qrRouting) {
              navigate(`/check/${id}`);
              setQrRouting(false);
              setId("");
            } else {
              navigate(infuNav ? "/all-influencers" : "/");
              settoggelaccount(false);
            }
          },
          (error) => {
            setLoadingLogin(false);
            if (error.response.status === 401) {
              settoggelaccount(true);
            }
          }
        );
      } catch (err) {}
    }
  };
  return (
    <Fragment>
      <div className="login-form-comp">
        <div className="login-form-title">
          <h3
            style={
              labelSmoue
                ? {
                    display: "none",
                  }
                : mshorWebsite
                ? { color: "#212121" }
                : {}
            }
          >
            مرحبا بك من جديد
          </h3>
          <h6
            style={
              labelSmoue
                ? {
                    display: "none",
                  }
                : mshorWebsite
                ? { color: "#000000" }
                : {}
            }
          >
            احصل علي مؤثر ليساعدك في التسويق لعلامتك التجاريه من خلال موقعنا
          </h6>
        </div>
        <div className="login-form">
          {toogelaccount && (
            <div className="alert alert-danger hintMsg" role="alert">
              اسم المستخدم خطأ او كلمة المرور غير صحيحة
            </div>
          )}
          <form onSubmit={handleLogin}>
            <div className="form-sec">
              <label
                style={
                  labelSmoue || transWebsite || takWebsite || sharkWebsite
                    ? {
                        color: "white",
                      }
                    : atharWebsite
                    ? { color: "#000000" }
                    : mshorWebsite
                    ? { color: "#212121" }
                    : fadaaWebsite
                    ? { color: "white" }
                    : {}
                }
              >
                اسم المستخدم
              </label>
              <input
                style={
                  labelSmoue
                    ? {
                        opacity: ".6",
                        borderRadius: "12px",
                        padding: "1rem",
                      }
                    : inputMillion
                    ? { background: "black", color: "white" }
                    : takWebsite
                    ? {
                        background: "rgba(31, 31, 31, 1)",
                        color: "white",
                        border: "1px solid #FF0593",
                      }
                    : transWebsite
                    ? {
                        border: "1px solid #02C6C6",
                        background: "black",
                        color: "white",
                      }
                    : sharkWebsite
                    ? {
                        border: "1px solid #B559E8",
                        background: "#1F1F1F",
                        color: "white",
                      }
                    : atharWebsite
                    ? {
                        border: "1px solid #F15A3A",
                        background: "transparent",
                        color: "black",
                      }
                    : fadaaWebsite
                    ? {
                        border: "1px solid #7444BD",
                        background: "transparent",
                        color: "white",
                      }
                    : {}
                }
                type="text"
                placeholder="قم بإدخال اسم المستخدم الخاص بك"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              {focusUser && !checkUser && (
                <span className=" spanhint">مطلوب</span>
              )}
              {userToogelhint && <span className=" spanhint">مطلوب</span>}
            </div>
            <div className="form-sec">
              <label
                style={
                  labelSmoue || transWebsite || takWebsite || sharkWebsite
                    ? {
                        color: "white",
                      }
                    : atharWebsite
                    ? { color: "#000000" }
                    : mshorWebsite
                    ? { color: "#212121" }
                    : fadaaWebsite
                    ? { color: "white" }
                    : {}
                }
              >
                كلمة المرور
              </label>
              <div className="input-password-parent">
                <input
                  style={
                    labelSmoue
                      ? {
                          opacity: ".6",
                          borderRadius: "12px",
                          padding: "1rem",
                        }
                      : inputMillion
                      ? { background: "black", color: "white" }
                      : takWebsite
                      ? {
                          background: "rgba(31, 31, 31, 1)",
                          color: "white",
                          border: "1px solid #FF0593",
                        }
                      : transWebsite
                      ? {
                          border: "1px solid #02C6C6",
                          background: "black",
                          color: "white",
                        }
                      : sharkWebsite
                      ? {
                          border: "1px solid #B559E8",
                          background: "#1F1F1F",
                          color: "white",
                        }
                      : atharWebsite
                      ? {
                          border: "1px solid #F15A3A",
                          background: "transparent",
                          color: "black",
                        }
                      : fadaaWebsite
                      ? {
                          border: "1px solid #7444BD",
                          background: "transparent",
                          color: "white",
                        }
                      : {}
                  }
                  type={tooglePassword ? "password" : "text"}
                  placeholder="قم بإدخال كلمة المرور"
                  value={password}
                  onFocus={handelpass}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {eyeAppear && (
                  <div
                    className="eye-password-child"
                    style={
                      inputMillion
                        ? { background: "black", color: "white" }
                        : takWebsite || transWebsite || sharkWebsite
                        ? { color: "rgba(171, 171, 171, 0.55)" }
                        : {}
                    }
                    onClick={() => setTooglePassword(!tooglePassword)}
                  >
                    {tooglePassword ? <VscEye /> : <VscEyeClosed />}
                  </div>
                )}
              </div>

              {focuspass && !checkpass && (
                <span className=" spanhint">مطلوب</span>
              )}
              {passToogelhint && <span className=" spanhint">مطلوب</span>}
            </div>

            <button
              type="submit"
              style={
                btnDarb
                  ? {
                      background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                      color: "white",
                      border: "none",
                    }
                  : labelSmoue
                  ? {
                      background: `linear-gradient(to right,#DB3737, transparent 65%), linear-gradient(to left, #002762, transparent 215%)`,
                      color: "white",
                      border: "none",
                      borderRadius: "12px",
                      padding: ".8rem",
                    }
                  : takWebsite
                  ? {
                      backgroundColor: "#00F800",
                      borderRadius: "55px",
                      boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                      color: "black",
                    }
                  : sharkWebsite
                  ? {
                      backgroundColor: "#FA4B52",
                      color: "white",
                      border: "1px solid #FBFA2D",
                    }
                  : atharWebsite
                  ? {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "55px",
                      boxShadow: "#F15A3A 4px 5px 1px 0px",
                      color: "black",
                    }
                  :fadaaWebsite
                  ? {
                      backgroundColor: "#F5ED0C",
                      color: "black",
                    }
                  : {}
              }
            >
              {loadingLogin ? <LoadingButton /> : "تسجيل الدخول"}
            </button>
          </form>
          <div className="have-no-account">
            <span
              className="no-account"
              style={
                labelSmoue
                  ? {
                      color: "#EDEDED",
                    }
                  : {}
              }
            >
              {" "}
              ليس لديك حساب ؟
            </span>
            <Link to="/login/create-account">
              <span className="create-account" style={{ color: createColor }}>
                {" "}
                انشاء حساب
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
