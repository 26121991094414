import React, { Fragment, useEffect, useState } from "react";
import "./influencer-logs.css";
import SmouFooter from "../../smou/smou-footer/SmouFooter";
import InfuFooter from "../../components/infu-footer/InfuFooter";
import Footer from "../../components/footer/Footer";
import SmouNavbar from "../../smou/smou-components/smou-navbar/SmouNavbar";
import NavBar from "../../components/NabBar/NavBar";
import { GiSettingsKnobs } from "react-icons/gi";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { getApiUrl } from "../../helpers";
import leftArr from "../../assets/images/arrow-left.svg";
import rightArr from "../../assets/images/arrow-right.svg";
import LoadingButton from "../../components/loading-button/LoadingButton";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import azzrkUser from "../../assets/images/azzrk-user.webp";
import mardodUser from "../../assets/images/mardod-user.webp";
import atharUser from "../../assets/images/athar-user.webp";
import darbUser from "../../assets/images/darb-user.webp";
import warqaUser from "../../assets/images/warqa-user.webp";
import smoueUser from "../../assets/images/smoue-user.webp";
import rawnqUser from "../../assets/images/rawnq-user.png";
import mshorUser from "../../assets/images/mshor-user.png";
import sahmUser from "../../assets/images/sahm-user.png";
import rightArrMillion from "../../assets/images/arrow-right-million.png";
import leftArrMillion from "../../assets/images/arrow-left-million.png";

const InfluencersLogs = () => {
  const [filterToggle, setFilterToogle] = useState(false);
  const [divHovered, setDivHovered] = useState(false);
  const [filterName, setFilterName] = useState("منذ عــام");
  const [day, setDay] = useState(true);
  const [week, setWeek] = useState(true);
  const [twoWeek, setTwoWeek] = useState(true);
  const [month, setMonth] = useState(true);
  const [threeMonth, setThreeMonth] = useState(true);
  const [halfYear, setHalfYear] = useState(true);
  const [year, setYear] = useState(false);

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    SAHM: "https://influencer.sahhm.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var labelSmoue;
  var infuFoot;
  var millionWebsite;
  var defaultPhoto;
  var defaultRightArrow;
  var defaultLeftArrow;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var roquaWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      defaultPhoto = mardodUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.SMOUE:
      btnDarb = false;
      labelSmoue = true;
      infuFoot = false;
      defaultPhoto = smoueUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      labelSmoue = false;
      infuFoot = false;
      defaultPhoto = darbUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      defaultPhoto = warqaUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      defaultPhoto = atharUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      atharWebsite = true;
      break;

    case DOMAINS.INFU:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArrMillion;
      defaultLeftArrow = leftArrMillion;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArrMillion;
      defaultLeftArrow = leftArrMillion;
      break;

    case DOMAINS.SAHM:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      defaultPhoto = sahmUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArrMillion;
      defaultLeftArrow = leftArrMillion;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArrMillion;
      defaultLeftArrow = leftArrMillion;
      break;

    case DOMAINS.Roqua:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = true;
      defaultPhoto = rawnqUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.MSHOR:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = false;
      mshorWebsite = true;
      defaultPhoto = mshorUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = false;
      mshorWebsite = false;
      fadaaWebsite = true;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArrMillion;
      defaultLeftArrow = leftArrMillion;
      break;

    default:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      defaultPhoto = azzrkUser;
      defaultRightArrow = rightArr;
      defaultLeftArrow = leftArr;
      break;
  }
  function renderFooter() {
    if (labelSmoue) {
      return <SmouFooter />;
    } else if (infuFoot) {
      return <InfuFooter />;
    } else {
      return <Footer />;
    }
  }
  useEffect(() => {
    renderFooter();
  }, []);

  const [loading, setLoading] = useState(true);
  const [logs, setlogs] = useState();
  const [pagesLogs, setpagesLogs] = useState();
  const [currentpagLogs, setcurrentpageLogs] = useState();
  const [logsUrl, setlogsUrl] = useState(
    getApiUrl(`api/brands-logs/me/?date_range=365d`)
  );
  const [responseLogs, setresponselogs] = useState();
  const getLogs = () => {
    axios
      .get(logsUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        setcurrentpageLogs(res.data.current_page);
        setpagesLogs(res.data.pages);
        setresponselogs(res.data);
        setlogs(res.data.results);
        setLoading(false);
      });
  };
  useEffect(() => {
    getLogs();
  }, []);
  useEffect(() => {
    getLogs();
  }, [logsUrl]);

  const handelTime = (theTime) => {
    const previousDate = new Date(theTime);
    const currentDate = new Date();
    const timeDifferenceInMilliseconds = currentDate - previousDate;
    const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);

    let result;
    if (hours < 1) {
      result = ` منذ ${minutes} دقيقة`;
    } else if (hours < 24) {
      result = `منذ ${hours} ساعة و ${minutes % 60} دقيقة`;
    } else if (hours < 720) {
      const days = Math.floor(hours / 24);
      result = `منذ ${days} أيام و ${hours % 24} ساعة`;
    } else {
      const months = Math.floor(hours / (24 * 30));
      result = `منذ ${months} شهور و ${(hours % (24 * 30)) % 24} ساعة`;
    }

    return result;
  };

  function extractDateWithoutTime(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleFilterClick = (newUrl) => {
    setLoading(true);
    setlogsUrl(newUrl);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fragment>
      {labelSmoue ? <SmouNavbar /> : <NavBar />}
      <div
        className=" influencers-logs-parent"
        style={{
          background:
            millionWebsite || takWebsite || sharkWebsite
              ? "black"
              : transWebsite
              ? "rgb(31, 31, 31)"
              : fadaaWebsite
              ? "#170E26"
              : "",
        }}
      >
        <div className="influencers-logs">
          <div className="influencers-logs-title">
            <h1
              style={
                labelSmoue ||
                takWebsite ||
                transWebsite ||
                sharkWebsite ||
                fadaaWebsite
                  ? {
                      color: "white",
                    }
                  : atharWebsite
                  ? { color: "black" }
                  : {}
              }
            >
              الزيارات
            </h1>
            <div className="influencers-logs-filter">
              <div
                className="influencers-filter-sec2"
                onClick={() => setFilterToogle(!filterToggle)}
              >
                <div
                  className="logs-filter"
                  style={{
                    background: labelSmoue
                      ? "#022444"
                      : divHovered && labelSmoue
                      ? `linear-gradient(135deg, rgba(162, 32, 32, .9) 0%, rgba(152, 31, 33, .9) 12%, rgba(148, 30, 36, .9) 23%, rgba(139, 29, 37, .9) 32%, rgba(130, 28, 39, .9) 39%, rgba(125, 28, 41, .9) 45%, rgba(115, 28, 42, .9) 49%, rgba(105, 27, 45, .9) 52%, rgba(92, 25, 45, .9) 56%, rgba(82, 25, 48, .9) 60%, rgba(67, 24, 49, .9) 68%, rgba(46, 22, 49, .9) 81%, rgba(3, 23, 53, .9) 100%)`
                      : millionWebsite ||
                        takWebsite ||
                        transWebsite ||
                        sharkWebsite
                      ? "rgb(124, 126, 129)"
                      : atharWebsite
                      ? "white"
                      : fadaaWebsite
                      ? "#2E2E2E"
                      : "var(--primaryColor)",
                    color: atharWebsite
                      ? "#7C7E81"
                      : fadaaWebsite
                      ? "#7C7E81"
                      : "white",
                    borderRadius: atharWebsite ? "45px" : "",
                  }}
                >
                  <div
                    className={
                      filterToggle
                        ? "filter-drop-down filter-show"
                        : "filter-drop-down filter-hide "
                    }
                    style={{
                      backgroundColor:
                        millionWebsite ||
                        takWebsite ||
                        transWebsite ||
                        sharkWebsite
                          ? "rgb(124, 126, 129)"
                          : fadaaWebsite
                          ? "#2E2E2E"
                          : "",
                    }}
                  >
                    <ul>
                      <div
                        onClick={() => {
                          if (day) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=1d")
                            );
                            setFilterName("منذ 1 يوم");
                            setDay(false);
                            setWeek(true);
                            setTwoWeek(true);
                            setMonth(true);
                            setThreeMonth(true);
                            setHalfYear(true);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ 1 يوم
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (week) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=7d")
                            );
                            setFilterName("منذ أسبوع");
                            setDay(true);
                            setWeek(false);
                            setTwoWeek(true);
                            setMonth(true);
                            setThreeMonth(true);
                            setHalfYear(true);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ أسبوع{" "}
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (twoWeek) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=14d")
                            );
                            setFilterName("منذ أسبوعين");
                            setDay(true);
                            setWeek(true);
                            setTwoWeek(false);
                            setMonth(true);
                            setThreeMonth(true);
                            setHalfYear(true);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ أسبوعين{" "}
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (month) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=31d")
                            );
                            setFilterName("منذ شهر");
                            setDay(true);
                            setWeek(true);
                            setTwoWeek(true);
                            setMonth(false);
                            setThreeMonth(true);
                            setHalfYear(true);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ شهر{" "}
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (threeMonth) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=90d")
                            );
                            setFilterName("منذ 3 أشهر");
                            setDay(true);
                            setWeek(true);
                            setTwoWeek(true);
                            setMonth(true);
                            setThreeMonth(false);
                            setHalfYear(true);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ 3 أشهر{" "}
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (halfYear) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=180d")
                            );
                            setFilterName("منذ 6 أشهر");
                            setDay(true);
                            setWeek(true);
                            setTwoWeek(true);
                            setMonth(true);
                            setThreeMonth(true);
                            setHalfYear(false);
                            setYear(true);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ 6 أشهر{" "}
                        </li>
                      </div>
                      <div
                        onClick={() => {
                          if (year) {
                            handleFilterClick(
                              getApiUrl("api/brands-logs/?date_range=365d")
                            );
                            setFilterName("منذ عــام");
                            setDay(true);
                            setWeek(true);
                            setTwoWeek(true);
                            setMonth(true);
                            setThreeMonth(true);
                            setHalfYear(true);
                            setYear(false);
                          }
                        }}
                      >
                        <li
                          style={{
                            color:
                              millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite
                                ? "white"
                                : "",
                          }}
                        >
                          منذ عــام{" "}
                        </li>
                      </div>
                    </ul>
                  </div>
                  <GiSettingsKnobs />
                  <span>{filterName}</span>
                  {filterToggle ? <SlArrowUp /> : <SlArrowDown />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="logs">
          <div className={loading || logs.length === 0 ? "" : "logs-content"}>
            {loading ? (
              <LoadingSpinner />
            ) : logs.length === 0 ? (
              <div className="no-visit">
                <h2
                  style={{
                    color:
                      takWebsite || transWebsite || sharkWebsite || fadaaWebsite ? "white" : "",
                  }}
                >
                  لا يوجد زيارات سابقة
                </h2>
              </div>
            ) : (
              logs.map((el, index) => {
                return (
                  <div key={index}>
                    <div className="border-logs">
                      <div className="circle-logs"></div>
                      <div className="logs-head">
                        <img
                          src={
                            el.influencer_photo
                              ? el.influencer_photo
                              : defaultPhoto
                          }
                          alt="logs"
                        />
                        <p
                          className="influencer-log-word"
                          style={{
                            color:
                              takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                                ? "white"
                                : atharWebsite
                                ? "black"
                                : "",
                          }}
                        >
                          قامت{" "}
                          <span className="influencer-log-name">
                            {el.influencer_name}
                          </span>{" "}
                          بزيارة رمز ال QR الخاص بها
                          <br />
                          {extractDateWithoutTime(el.created)}
                        </p>
                      </div>
                      <div className="logs-time">
                        <p>{handelTime(el.created)}</p>
                      </div>
                    </div>
                    {index !== logs.length - 1 && (
                      <div className="line-break-logs"></div>
                    )}
                  </div>
                );
              })
            )}
          </div>

          {logs?.length > 0 && (
            <div className="paginate-arrow">
              <img
                src={defaultRightArrow}
                alt="arrow"
                onClick={() => {
                  if (responseLogs.next) {
                    setLoading(true);
                    setlogsUrl(responseLogs.next);
                  }
                }}
              />
              <h6
                style={{
                  margin: "0",
                  color:
                    millionWebsite || takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                      ? "white"
                      : "",
                }}
              >
                {currentpagLogs}
                <span className="paginate-word">out of </span> {pagesLogs}
              </h6>
              <img
                src={defaultLeftArrow}
                alt="arrow"
                onClick={() => {
                  if (responseLogs.previous) {
                    setLoading(true);
                    setlogsUrl(responseLogs.previous);
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      {renderFooter()}
    </Fragment>
  );
};

export default InfluencersLogs;
