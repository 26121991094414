import React from "react";
import NavBar from "../NabBar/NavBar";
import Footer from "../footer/Footer";
import SmouFooter from "../../smou/smou-footer/SmouFooter";
import InfuFooter from "../infu-footer/InfuFooter";
import { useEffect } from "react";
import infuImage from "../../assets/images/infu-error.png";
import azzrkImage from "../../assets/images/azzrk-error.png";
import atherImage from "../../assets/images/ather-error.png";
import mardodImage from "../../assets/images/marddod-error.png";
import warqaImage from "../../assets/images/warka-error.png";
import darbImage from "../../assets/images/darb-error.png";
import smouImage from "../../assets/images/smou-error.png";
import sahmImage from "../../assets/images/sahm-error.png";
import khatwaImag from "../../assets/images/khatwa-error.png";
import rawnqImage from "../../assets/images/rawnk-error.png";
import basmaImage from "../../assets/images/basma-error.png";
import millionImage from "../../assets/images/million-error.png";
import tackImage from "../../assets/images/tack-error.png";
import sharkImage from "../../assets/images/shark-error.png";
import transImage from "../../assets/images/trans-error.png";
import roquaImage from "../../assets/images/roqua-error.png";
import mshorImage from "../../assets/images/mshor-error.png";
import fadaaImage from "../../assets/images/fadaa-error.png";
import SmouNavbar from "../../smou/smou-components/smou-navbar/SmouNavbar";

const NotFound = () => {
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var subdomain = window.location.origin;
  var smuFoot;
  var infuFoot;
  var imageNotfound;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      imageNotfound = azzrkImage;
      infuFoot = false;
      smuFoot = false;

      break;
    case DOMAINS.ATHAR:
      imageNotfound = atherImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.MARDOD:
      imageNotfound = mardodImage;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.WARAQA:
      imageNotfound = warqaImage;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.DARB:
      imageNotfound = darbImage;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.SAHM:
      imageNotfound = sahmImage;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.KHATWA:
      imageNotfound = khatwaImag;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.RAWNQ:
      imageNotfound = rawnqImage;
      infuFoot = false;
      smuFoot = false;
      break;
    case DOMAINS.BASMA:
      imageNotfound = basmaImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.SMOUE:
      imageNotfound = smouImage;
      infuFoot = false;
      smuFoot = true;
      break;
    case DOMAINS.INFU:
      imageNotfound = infuImage;
      infuFoot = true;
      smuFoot = false;
      break;

    case DOMAINS.MILLION:
      imageNotfound = millionImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.TAK:
      imageNotfound = tackImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.SHARK:
      imageNotfound = sharkImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.TRANS:
      imageNotfound = transImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.Roqua:
      imageNotfound = roquaImage;
      infuFoot = false;
      smuFoot = false;
      break;

    case DOMAINS.MSHOR:
      imageNotfound = mshorImage;
      infuFoot = false;
      smuFoot = false;
      break;

      case DOMAINS.FADAA:
        imageNotfound = fadaaImage;
        infuFoot = false;
        smuFoot = false;
        break;

    default:
      imageNotfound = azzrkImage;
      infuFoot = false;
      smuFoot = false;
      break;
  }
  function renderFooter() {
    if (smuFoot) {
      return <SmouFooter />;
    } else if (infuFoot) {
      return <InfuFooter />;
    } else {
      return <Footer />;
    }
  }
  useEffect(() => {
    renderFooter();
  }, []);
  return (
    <div>
      {smuFoot ? <SmouNavbar /> : <NavBar />}
      <div className="image-error">
        <img src={imageNotfound} alt="notFound" className="w-100" />
      </div>
      {renderFooter()}
    </div>
  );
};

export default NotFound;
