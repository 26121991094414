import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./footer.css";
import homeIcon from "./../../assets/images/footer-images/home.png";
import peopleIcon from "./../../assets/images/footer-images/people.png";
import influencerIcon from "./../../assets/images/footer-images/influencerIcon.png";
import azzrkFooterBack from "../../assets/images/azzrk-footer-back.webp";
import mardodFooterBack from "../../assets/images/mardod-footer-back.webp";
import darbFooterBack from "../../assets/images/darb-footer-back.webp";
import warqaFooterBack from "../../assets/images/warqa-footer-back.webp";
import atharFooterBack from "../../assets/images/athar-footer-back.png";
import sahmFooterBack from "../../assets/images/sahm-footer-back.png";
import khatwaFooterBack from "../../assets/images/khatwa-footer-back.png";
import rawnqFooterBack from "../../assets/images/rawnq-footer-back.png";
import basmaFooterBack from "../../assets/images/basma-footer-back.png";
import millionFooterBack from "../../assets/images/million-footer-back.png";
import takFooterBack from "../../assets/images/tak-subhero-back.png";
import transFooterBack from "../../assets/images/tak-subhero-back.png";
import roquaFooterBack from "../../assets/images/roqua-subhero-back.png";
import mshorFooterBack from "../../assets/images/mshor-footer-back.png";
import fadaaFooterBack from "../../assets/images/fadaa-footer-back.png";
import azzrkLogo from "../../assets/images/logoedited.webp";
import mardodLogo from "../../assets/images/mardod-logo-footer.webp";
import atharLogo from "../../assets/images/athar-logo-footer.png";
import darbLogo from "../../assets/images/darb-logo-footer.webp";
import warqaLogo from "../../assets/images/warqa-logo-footer.webp";
import sahmLogo from "../../assets/images/sahm-logo-footer.png";
import khatwaLogo from "../../assets/images/khatwa-logo-footer.png";
import rawnqLogo from "../../assets/images/rawnq-logo-footer.png";
import basmaLogo from "../../assets/images/basma-logo-footer.png";
import infuLogo from "../../assets/images/infu-logo-footer.png";
import millionLogo from "../../assets/images/million-logo-footer.png";
import takLogo from "../../assets/images/tak-logo-footer.png";
import transLogo from "../../assets/images/trans-logo-footer.png";
import sharkLogo from "../../assets/images/shark-logo.png";
import roquaLogo from "../../assets/images/roqua-logo-footer.png";
import mshorLogo from "../../assets/images/mshor-logo-footer.png";
import fadaaLogo from "../../assets/images/fadaa-logo-footer.png";
import scanIcon from "../../assets/images/scan-icon.png";

const Footer = () => {
  const [toggleSetting, setToggleSetting] = useState(false);
  const location = useLocation();
  const brand = JSON.parse(localStorage.getItem("brand"));
  useEffect(() => {
    setToggleSetting(brand ? true : false);
  }, [brand]);
  const scrollToPercent = (percent) => {
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const scrollToPixel =
      (percent / 100) * (document.documentElement.scrollHeight - windowHeight);

    window.scrollTo({ top: scrollToPixel, behavior: "smooth" });
  };

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var footerBackground;
  var logo;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      footerBackground = azzrkFooterBack;
      logo = azzrkLogo;
      break;

    case DOMAINS.MARDOD:
      footerBackground = mardodFooterBack;
      logo = mardodLogo;
      break;

    case DOMAINS.DARB:
      footerBackground = darbFooterBack;
      logo = darbLogo;
      break;

    case DOMAINS.WARAQA:
      footerBackground = warqaFooterBack;
      logo = warqaLogo;
      break;

    case DOMAINS.ATHAR:
      footerBackground = atharFooterBack;
      logo = atharLogo;
      atharWebsite = true;
      break;

    case DOMAINS.SAHM:
      footerBackground = sahmFooterBack;
      logo = sahmLogo;
      break;

    case DOMAINS.KHATWA:
      footerBackground = khatwaFooterBack;
      logo = khatwaLogo;
      break;

    case DOMAINS.RAWNQ:
      footerBackground = rawnqFooterBack;
      logo = rawnqLogo;
      break;

    case DOMAINS.BASMA:
      footerBackground = basmaFooterBack;
      logo = basmaLogo;
      break;

    case DOMAINS.INFU:
      footerBackground = basmaFooterBack;
      logo = infuLogo;
      break;

    case DOMAINS.MILLION:
      footerBackground = millionFooterBack;
      logo = millionLogo;
      break;

    case DOMAINS.TAK:
      footerBackground = takFooterBack;
      logo = takLogo;
      break;

    case DOMAINS.TRANS:
      logo = transLogo;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      logo = sharkLogo;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.Roqua:
      footerBackground = roquaFooterBack;
      logo = roquaLogo;
      transWebsite = false;
      sharkWebsite = false;
      break;

    case DOMAINS.MSHOR:
      footerBackground = mshorFooterBack;
      logo = mshorLogo;
      transWebsite = false;
      sharkWebsite = false;
      break;

    case DOMAINS.FADAA:
      footerBackground = fadaaFooterBack;
      logo = fadaaLogo;
      transWebsite = false;
      sharkWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      footerBackground = azzrkFooterBack;
      transWebsite = false;
      logo = azzrkLogo;
  }

  return (
    <footer
      className="footer"
      style={{
        background: transWebsite
          ? "rgb(15 185 185)"
          : sharkWebsite
          ? "#000000"
          : "",
        backgroundImage: `url(${footerBackground})`,
      }}
    >
      <div className="container">
        <div className="footer__inner">
          <div className="col-one footer-logo">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="footer-info">
            <div className="col-two">
              <h1>وصول سريع</h1>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to="/"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <div>
                      <img src={homeIcon} alt="" />
                    </div>
                    <p>الرئيسية</p>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      if (location.pathname === "/") {
                        const sectionElement =
                          document.querySelector("#who-us");
                        if (sectionElement) {
                          sectionElement.scrollIntoView({ behavior: "smooth" });
                        }
                      } else {
                        scrollToPercent(42);
                      }
                    }}
                  >
                    <div>
                      <img src={peopleIcon} alt="" />
                    </div>
                    <p>من نحن</p>
                  </Link>
                </li>
                <li>
                  <NavLink
                    to={brand ? "/all-influencers" : "login"}
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <div>
                      <img src={influencerIcon} alt="linkedin logo" />
                    </div>
                    <p>المؤثرين</p>
                  </NavLink>
                </li>
                {brand && (
                  <li>
                    <NavLink
                      to={brand ? "/influencers-logs" : "login"}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div>
                        <img src={scanIcon} alt="linkedin logo" />
                      </div>
                      <p>الزيارات</p>
                    </NavLink>
                  </li>
                )}
                {/*<li>
                  <Link to="/">
                    <div>
                      <img src={messageIcon} alt="linkedin logo" />
                    </div>
                    <p>تواصل معنا</p>
                  </Link>
                </li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
