import React, { Fragment } from "react";
import "./login-page.css";
import { Outlet } from "react-router";
import azzrkLogo from "../../assets/images/azzrak-logo.webp";
import mardodLogo from "../../assets/images/mardod-logo.webp";
import atharLogo from "../../assets/images/athar-logo.png";
import darbLogo from "../../assets/images/darb-logo.webp";
import warqaLogo from "../../assets/images/warqa-logo.webp";
import sahmLogo from "../../assets/images/sahm-logo.png";
import khatwaLogo from "../../assets/images/khatwa-logo.png";
import rawnqLogo from "../../assets/images/rawnq-logo.png";
import basmaLogo from "../../assets/images/basma-logo.png";
import infuLogo from "../../assets/images/infu-logo.png";
import millionLogo from "../../assets/images/million-logo.png";
import takLogo from "../../assets/images/tak-logo.png";
import transLogo from "../../assets/images/trans-logo.png";
import sharkLogo from "../../assets/images/shark-logo.png";
import roquaLogo from "../../assets/images/roqua-logo.png";
import mshorLogo from "../../assets/images/mshor-logo.png";
import fadaaLogo from "../../assets/images/fada-logo.png";
import azzrkLoginBack from "../../assets/images/azzrk-login-back.webp";
import mardodLoginBack from "../../assets/images/mardod-login-back.webp";
import darbLoginBack from "../../assets/images/darb-login-back.webp";
import waraqaLoginBack from "../../assets/images/warqa-login-back.webp";
import atharLoginBack from "../../assets/images/athar-login-back.png";
import sahmLoginBack from "../../assets/images/sahm-login-back.png";
import khatwaLoginBack from "../../assets/images/khatwa-login-back.png";
import rawnqLoginBack from "../../assets/images/rawnq-login-back.png";
import basmaLoginBack from "../../assets/images/basma-login-back.png";
import infuLoginBack from "../../assets/images/infu-login-back.png";
import millionLoginBack from "../../assets/images/million-login-back.png";
import takLoginBack from "../../assets/images/tak-login-back.png";
import transLoginBack from "../../assets/images/trans-login-back.png";
import sharkLoginBack from "../../assets/images/shark-login-back.png";
import roquaLoginBack from "../../assets/images/roqua-login-back.png";
import mshorLoginBack from "../../assets/images/mshor-login-back.png";
import fadaaLoginBack from "../../assets/images/fada-login-back.png";
import fadaaBackOutlet from "../../assets/images/fada-login-outlet.png";
import { useNavigate } from "react-router-dom";

var subdomain = window.location.origin;
const DOMAINS = {
  AZZRK: "https://influencer.azzrk.com",
  ATHAR: "https://influencer.atherr.com",
  MARDOD: "https://influencer.marrdoud.com",
  WARAQA: "https://influencer.warrqa.com",
  DARB: "https://influencer.darbplatform.com",
  SMOUE: "https://influencer.sumoue.com",
  SAHM: "https://influencer.sahhm.com",
  KHATWA: "https://influencer.khatwaa.com",
  RAWNQ: "https://influencer.rawnkagency.com",
  BASMA: "https://influencer.basmahagency.com",
  INFU: "https://dashboard.infugram.com",
  MILLION: "https://influencer.one1million.com",
  TAK: "https://influencer.agencytak.com",
  TRANS: "https://influencer.transition-agency.com",
  SHARK: "https://influencer.shark-company.com",
  Roqua: "https://influencer.roaya-marketing.com",
  MSHOR: "https://influencer.mshourr.com",
  FADAA: "https://influencer.fadaaagency.com",
};

var loginBackground;
var backPosition;
var backSize;
var backColor;
var backgroundImage1;
var backgroundImage2;
var divHeight;
var logo;
var millionBorder;
var takWebsite;
var transWebsite;
var sharkWebsite;
var atharWebsite;
var fadaaWebsite;

switch (subdomain) {
  case DOMAINS.AZZRK:
    loginBackground = azzrkLoginBack;
    backPosition = "center";
    backSize = "auto";
    backColor = "#022444";
    backgroundImage1 =
      "linear-gradient(to right, rgba(118, 197, 245, .5) , rgba(188, 230, 255, 1))";
    backgroundImage2 =
      "linear-gradient(to left, rgba(118, 197, 245, .5) , rgba(153, 216, 255, 1))";
    divHeight = "96vh";
    logo = azzrkLogo;
    takWebsite = false;
    break;

  case DOMAINS.MARDOD:
    loginBackground = mardodLoginBack;
    backPosition = "auto";
    backSize = "auto";
    backColor = "#16EDB1";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = mardodLogo;
    takWebsite = false;
    break;

  case DOMAINS.SMOUE:
    backgroundImage1 = "";
    backgroundImage2 = "";
    takWebsite = false;
    break;

  case DOMAINS.DARB:
    loginBackground = darbLoginBack;
    backSize = "cover";
    backPosition = "center";
    backColor = "#25c7e2";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = darbLogo;
    takWebsite = false;
    break;

  case DOMAINS.WARAQA:
    loginBackground = waraqaLoginBack;
    backPosition = "left";
    backSize = "auto";
    backColor = "#FF6A00";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = warqaLogo;
    takWebsite = false;
    break;

  case DOMAINS.ATHAR:
    loginBackground = atharLoginBack;
    backColor = "";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = atharLogo;
    takWebsite = false;
    atharWebsite = true;
    break;

  case DOMAINS.SAHM:
    loginBackground = sahmLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = sahmLogo;
    takWebsite = false;
    break;

  case DOMAINS.INFU:
    loginBackground = infuLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = infuLogo;
    takWebsite = false;
    break;

  case DOMAINS.KHATWA:
    loginBackground = khatwaLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = khatwaLogo;
    takWebsite = false;
    break;

  case DOMAINS.RAWNQ:
    loginBackground = rawnqLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = rawnqLogo;
    takWebsite = false;
    break;

  case DOMAINS.BASMA:
    loginBackground = basmaLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = basmaLogo;
    takWebsite = false;
    break;

  case DOMAINS.MILLION:
    loginBackground = millionLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = millionLogo;
    millionBorder = true;
    takWebsite = false;
    break;

  case DOMAINS.TAK:
    loginBackground = takLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = takLogo;
    millionBorder = false;
    takWebsite = true;
    break;

  case DOMAINS.TRANS:
    loginBackground = transLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = transLogo;
    millionBorder = false;
    takWebsite = false;
    transWebsite = true;
    break;

  case DOMAINS.SHARK:
    loginBackground = sharkLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = sharkLogo;
    millionBorder = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = true;
    break;

  case DOMAINS.Roqua:
    loginBackground = roquaLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = roquaLogo;
    millionBorder = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    break;

  case DOMAINS.MSHOR:
    loginBackground = mshorLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = "";
    divHeight = "100vh";
    logo = mshorLogo;
    millionBorder = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    break;

  case DOMAINS.FADAA:
    loginBackground = fadaaLoginBack;
    backColor = "transparent";
    backgroundImage1 = "";
    backgroundImage2 = fadaaBackOutlet;
    divHeight = "100vh";
    logo = fadaaLogo;
    millionBorder = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    fadaaWebsite = true;
    break;

  default:
    loginBackground = azzrkLoginBack;
    backPosition = "center";
    backSize = "auto";
    backColor = "#022444";
    backgroundImage1 =
      "linear-gradient(to right, rgba(118, 197, 245, .5) , rgba(188, 230, 255, 1))";
    backgroundImage2 =
      "linear-gradient(to left, rgba(118, 197, 245, .5) , rgba(153, 216, 255, 1))";
    divHeight = "96vh";
    logo = azzrkLogo;
    millionBorder = false;
    takWebsite = false;
}

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div
        className="login-page"
        style={{
          backgroundImage: `url(${loginBackground})`,
          backgroundPosition: backPosition,
          backgroundSize: backSize,
          backgroundColor: backColor,
        }}
      >
        <div
          className="outlet-parent"
          style={{
            backgroundColor:
              millionBorder || takWebsite || transWebsite
                ? "black"
                : sharkWebsite
                ? "#1F1F1F"
                : "",
          }}
        >
          <div
            className="outlet"
            style={{
              backgroundImage: backgroundImage1,
              borderRadius: fadaaWebsite ? "0" : "",
            }}
          >
            <div
              className="outlet-subparent"
              style={{
                backgroundImage: fadaaWebsite ? `url(${fadaaBackOutlet})` :backgroundImage2,
                height: fadaaWebsite ? "100vh" : "",
                borderRadius: fadaaWebsite ? "0px" : "",
                backgroundRepeat:"no-repeat",
                backgroundSize:"cover"
              }}
            >
              <div
                className="outlet-child"
                style={{
                  height: divHeight,
                  border:
                    millionBorder || takWebsite || sharkWebsite
                      ? "1px solid #B1B1B1"
                      : "",
                  backgroundColor: transWebsite
                    ? "black"
                    : sharkWebsite
                    ? "#1F1F1F"
                    : atharWebsite
                    ? "#F1F1F1"
                    : "",
                  borderRadius:
                    transWebsite || sharkWebsite || atharWebsite || fadaaWebsite
                      ? "0"
                      : "",
                }}
              >
                <div className="login-page-logo">
                  <img
                    src={logo}
                    alt=""
                    width=""
                    height=""
                    className="login-azzrak-logo"
                  />
                </div>
                <div className="outlet-outlet">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="login-poster"
          onClick={() => {
            navigate("/");
          }}
        ></div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
