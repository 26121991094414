import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingButton = () => {
  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
  };
  var millionWebsite;
  var takWebsite;
  var atharWebsite;

  switch (subdomain) {
    case DOMAINS.MILLION:
      millionWebsite = true;
      break;
      case DOMAINS.TAK:
        millionWebsite = false;
        takWebsite = true;
        break;
        case DOMAINS.ATHAR:
          millionWebsite = false;
          atharWebsite = true;
          break;
    default:
      millionWebsite = false;
      takWebsite = false;

  }
  return (
    <div>
      <RotatingLines
        strokeColor={millionWebsite || takWebsite || atharWebsite ? "black" : "white"}
        strokeWidth="5"
        animationDuration="0.75"
        width="40"
        visible={true}
      />
    </div>
  );
};

export default LoadingButton;
