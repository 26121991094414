import React, { Fragment, useState, useEffect } from "react";
import "./all-influencers.css";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NabBar/NavBar";
import Card from "../../components/card/Card";
import { GiSettingsKnobs } from "react-icons/gi";
import { SlArrowRight } from "react-icons/sl";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import Footer from "../../components/footer/Footer";
import StayInContact from "../../components/stay-in-contact/StayInContact";
import authService from "../../services/auth-services";
import { getApiUrl } from "../../helpers";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import smoueBg from "../../smou/smou-imgs/smoue-all-influencer-bg.webp";
import SmouNavbar from "../../smou/smou-components/smou-navbar/SmouNavbar";
import SmouFooter from "../../smou/smou-footer/SmouFooter.jsx";
import InfuFooter from "../../components/infu-footer/InfuFooter.jsx";

const AllInfluencers = () => {
  const [filterToggle, setFilterToogle] = useState(false);
  const [allInfluencers, setAllInfluencers] = useState();
  const [response, setResponse] = useState();
  const [filterName, setFilterName] = useState("الكل");
  const [all, setAll] = useState(false);
  const [choosen, setChoosen] = useState(true);
  const [notChoosen, setNoChoosen] = useState(true);
  const [allInfluencersUrl, setAllInfluencersUrl] = useState(
    getApiUrl("api/brands/me/influencers/")
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getTopInfluencers = () => {
    setLoading(true);
    authService
      .allInfluencers(allInfluencersUrl)
      .then((response) => {
        setAllInfluencers(response.results);
        setResponse(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          localStorage.removeItem("brand");
          navigate("/login");
        }
        setLoading(false); // Ensure loading state is set to false even in case of error
      });
  };

  const handleFilterClick = (newUrl) => {
    setLoading(true);
    setAllInfluencersUrl(newUrl);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getTopInfluencers();
  }, [allInfluencersUrl]);

  const numbersLoop = () => {
    if (response.num_pages) {
      for (let i = 0; i <= response.num_pages - 1; i++) {}
    }
  };
  useEffect(() => {
    if (response) {
      numbersLoop();
    }
  }, [response]);
  const [activePage, setActivePage] = useState(0);
  const [activeArrow, setActiveArrow] = useState("");

  const handlePagination = (index) => {
    setHoveredIndex(-1);
    if (allInfluencersUrl) {
      const url = new URL(allInfluencersUrl);
      const baseUrl = url.origin + url.pathname;
      const queryParams = new URLSearchParams(url.search);

      const newPage = index + 1;
      if (queryParams.has("page")) {
        queryParams.set("page", newPage);
      } else {
        queryParams.append("page", newPage);
      }

      const newUrl = baseUrl + "?" + queryParams.toString();
      setAllInfluencersUrl(newUrl);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setActivePage(index);
    } else {
      setAllInfluencersUrl(getApiUrl(`${allInfluencersUrl}?page=${index + 1}`));
      window.scrollTo({ top: 0, behavior: "smooth" });
      setActivePage(index);
    }
  };

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var labelSmoue;
  var infuFoot;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var roquaWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.SMOUE:
      btnDarb = false;
      labelSmoue = true;
      infuFoot = false;

      break;

    case DOMAINS.DARB:
      btnDarb = true;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      atharWebsite = true;
      break;

    case DOMAINS.INFU:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = true;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = true;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.Roqua:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = true;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      roquaWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      takWebsite = false;
      break;
  }
  function renderFooter() {
    if (labelSmoue) {
      return <SmouFooter />;
    } else if (infuFoot) {
      return <InfuFooter />;
    } else {
      return <Footer />;
    }
  }
  useEffect(() => {
    renderFooter();
  }, []);

  const [divHovered, setDivHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  return (
    <Fragment>
      {labelSmoue ? <SmouNavbar /> : <NavBar />}
      <div
        style={
          labelSmoue
            ? {
                backgroundImage: `url(${smoueBg})`,
                backgroundSize: "cover",
              }
            : millionWebsite || takWebsite || transWebsite || sharkWebsite
            ? { backgroundColor: "black" }
            : fadaaWebsite
            ? { backgroundColor: "#170E26" }
            : {}
        }
      >
        <div className="all-influencers container">
          <div className="all-influencers-header">
            <div className="all-influencers-title">
              <h1
                style={
                  labelSmoue ||
                  millionWebsite ||
                  takWebsite ||
                  transWebsite ||
                  fadaaWebsite
                    ? {
                        color: "white",
                      }
                    : sharkWebsite
                    ? { color: "#FA4B52" }
                    : atharWebsite
                    ? { color: "#F15A3A" }
                    : roquaWebsite
                    ? { color: "#085D60" }
                    : {}
                }
              >
                المؤثرين
              </h1>
            </div>
            <div
              className={"all-influencers-filter"}
              onClick={() => setFilterToogle(!filterToggle)}
              onMouseEnter={() => {
                if (labelSmoue) {
                  setDivHovered(true);
                }
              }}
              onMouseLeave={() => {
                setDivHovered(false);
              }}
              style={{
                background: labelSmoue
                  ? "var(--cardColor)"
                  : divHovered && labelSmoue
                  ? `linear-gradient(135deg, rgba(162, 32, 32, .9) 0%, rgba(152, 31, 33, .9) 12%, rgba(148, 30, 36, .9) 23%, rgba(139, 29, 37, .9) 32%, rgba(130, 28, 39, .9) 39%, rgba(125, 28, 41, .9) 45%, rgba(115, 28, 42, .9) 49%, rgba(105, 27, 45, .9) 52%, rgba(92, 25, 45, .9) 56%, rgba(82, 25, 48, .9) 60%, rgba(67, 24, 49, .9) 68%, rgba(46, 22, 49, .9) 81%, rgba(3, 23, 53, .9) 100%)`
                  : millionWebsite || takWebsite || transWebsite || sharkWebsite
                  ? "#7C7E81"
                  : atharWebsite
                  ? "white"
                  : fadaaWebsite
                  ? "#2E2E2E"
                  : "var(--primaryColor)",
                color: atharWebsite
                  ? "#7C7E81"
                  : fadaaWebsite
                  ? "#7C7E81"
                  : "white",
                borderRadius: atharWebsite ? "45px" : "",
              }}
            >
              <div
                className={
                  filterToggle
                    ? "filter-drop-down filter-show"
                    : "filter-drop-down filter-hide"
                }
                style={{
                  backgroundColor:
                    millionWebsite || takWebsite || transWebsite || sharkWebsite
                      ? "#7C7E81"
                      : fadaaWebsite
                      ? "#2E2E2E"
                      : "",
                }}
              >
                <ul>
                  <div
                    onClick={() => {
                      if (all) {
                        handleFilterClick(
                          getApiUrl("api/brands/me/influencers/ ")
                        );
                        setFilterName("الكل");
                      }
                      setAll(false);
                      setChoosen(true);
                      setNoChoosen(true);
                    }}
                  >
                    <li
                      style={{
                        color:
                          millionWebsite ||
                          takWebsite ||
                          transWebsite ||
                          sharkWebsite
                            ? "white"
                            : "",
                      }}
                    >
                      الكل
                    </li>
                  </div>
                  <div
                    onClick={() => {
                      if (all === false) {
                        setAll(true);
                      }
                      if (notChoosen === false) {
                        setNoChoosen(true);
                      }
                      if (choosen) {
                        handleFilterClick(
                          getApiUrl("api/brands/me/influencers/?accepted=true")
                        );
                        setFilterName("المؤثرين المختارون");
                        setChoosen(false);
                      }
                    }}
                  >
                    <li
                      style={{
                        color:
                          millionWebsite ||
                          takWebsite ||
                          transWebsite ||
                          sharkWebsite
                            ? "white"
                            : "",
                      }}
                    >
                      المؤثرين المختارون
                    </li>
                  </div>
                  <div
                    onClick={() => {
                      if (all === false) {
                        setAll(true);
                      }
                      if (choosen === false) {
                        setChoosen(true);
                      }
                      if (notChoosen) {
                        handleFilterClick(
                          getApiUrl("api/brands/me/influencers/?accepted=false")
                        );
                        setFilterName("المؤثرين الغير مختارون");
                        setNoChoosen(false);
                      }
                    }}
                  >
                    <li
                      style={{
                        color:
                          millionWebsite ||
                          takWebsite ||
                          transWebsite ||
                          sharkWebsite
                            ? "white"
                            : "",
                      }}
                    >
                      المؤثرين الغير مختارون
                    </li>
                  </div>
                </ul>
              </div>
              <GiSettingsKnobs />
              <span>{filterName}</span>
              <span>{filterToggle ? <SlArrowUp /> : <SlArrowDown />}</span>
            </div>
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {allInfluencers?.length === 0 ? (
                <div className="no-visit">
                  <h2
                    style={{
                      color:
                        labelSmoue ||
                        millionWebsite ||
                        takWebsite ||
                        transWebsite ||
                        sharkWebsite ||
                        fadaaWebsite
                          ? "white"
                          : atharWebsite
                          ? { color: "#F15A3A" }
                          : "",
                    }}
                  >
                    لا يوجد لديك مؤثرين سابقين
                  </h2>
                </div>
              ) : (
                <div className="card-con container">
                  {allInfluencers?.map((topInfluencer, index) => (
                    <div className="" key={index}>
                      <Card
                        topInfluencers={allInfluencers[index]}
                        getTopInfluencers={getTopInfluencers}
                        allInfluencersUrl={allInfluencersUrl}
                        className="influencer-card"
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {response?.num_pages > 1 && (
            <div
              className={
                millionWebsite
                  ? "all-influencers-pagination all-influencers-pagination-million container"
                  : transWebsite
                  ? "all-influencers-pagination all-influencers-pagination-trans container"
                  : "all-influencers-pagination container"
              }
            >
              <div
                className={`pagination-arrow-${
                  response.previous ? "right" : "left"
                } ${
                  activeArrow === (response.previous ? "left" : "right")
                    ? "active"
                    : ""
                } `}
                onClick={() => {
                  if (response.previous) {
                    setAllInfluencersUrl(response.previous);
                    setActiveArrow("right");
                    setActivePage(activePage - 1);
                  }
                }}
                style={
                  labelSmoue && response.previous
                    ? {
                        borderRadius: "7px",
                        color: "#E0E0E0",
                        background:
                          "linear-gradient(135deg, rgb(162, 32, 32,.9) 0%, rgb(152, 31, 33,.9) 12%, rgb(148, 30, 36,.9) 23%, rgb(139, 29, 37,.9) 32%, rgb(130, 28, 39,.9) 39%, rgb(125, 28, 41,.9) 45%, rgb(115, 28, 42,.9) 49%, rgb(105, 27, 45,.9) 52%, rgb(92, 25, 45,.9) 56%, rgb(82, 25, 48,.9) 60%, rgb(67, 24, 49,.9) 68%, rgb(46, 22, 49,.9) 81%, rgb(3, 23, 53,.9) 100%)",
                      }
                    : labelSmoue && !response.previous
                    ? {
                        backgroundColor: "rgba(255,255,255,43%)",
                        borderRadius: "10px",
                        color: "white",
                      }
                    : (millionWebsite && !response.previous) ||
                      (takWebsite && !response.previous)
                    ? { background: "#1F1F1F", color: "white" }
                    : (transWebsite && !response.previous) ||
                      (transWebsite && !response.previous) ||
                      (sharkWebsite && !response.previous) ||
                      (fadaaWebsite && !response.previous)
                    ? { background: "#1F1F1F", color: "white" }
                    : sharkWebsite && response.previous
                    ? {
                        color: "white",
                        background: "#FA4B52",
                      }
                    : {}
                }
              >
                <SlArrowRight />
              </div>
              <div
                className={
                  btnDarb
                    ? "pagination-numbers darb"
                    : millionWebsite || takWebsite || transWebsite
                    ? "pagination-numbers pagination-numbers-million"
                    : atharWebsite
                    ? "pagination-numbers pagination-numbers-athar"
                    : "pagination-numbers websites"
                }
                style={
                  labelSmoue
                    ? {
                        borderRadius: "10px",
                        backgroundColor: "rgba(255,255,255,.43)",
                      }
                    : sharkWebsite || fadaaWebsite
                    ? {
                        backgroundColor: "#1F1F1F",
                        color: "white",
                      }
                    : {}
                }
              >
                {response && response.num_pages
                  ? Array.from({ length: response.num_pages }, (_, index) => {
                      const startPage = activePage >= 5 ? activePage - 4 : 0;
                      const endPage =
                        startPage + 5 < response.num_pages
                          ? startPage + 5
                          : response.num_pages;

                      if (index >= startPage && index < endPage) {
                        return (
                          <span
                            key={index}
                            onClick={() => handlePagination(index)}
                            className={index === activePage ? "active" : ""}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(-1)}
                            //here
                            style={{
                              color:
                                (index === activePage) & millionWebsite
                                  ? "black"
                                  : index === activePage && "white",
                              background:
                                index === hoveredIndex && labelSmoue
                                  ? "linear-gradient(135deg, rgb(162, 32, 32,.9) 0%, rgb(152, 31, 33,.9) 12%, rgb(148, 30, 36,.9) 23%, rgb(139, 29, 37,.9) 32%, rgb(130, 28, 39,.9) 39%, rgb(125, 28, 41,.9) 45%, rgb(115, 28, 42,.9) 49%, rgb(105, 27, 45,.9) 52%, rgb(92, 25, 45,.9) 56%, rgb(82, 25, 48,.9) 60%, rgb(67, 24, 49,.9) 68%, rgb(46, 22, 49,.9) 81%, rgb(3, 23, 53,.9) 100%)"
                                  : index === hoveredIndex && btnDarb
                                  ? "linear-gradient(225.15deg, #0C2241 -5.43%, #0CD7CD 100%)"
                                  : index === activePage
                                  ? "var(--activePagenation)"
                                  : index === hoveredIndex && sharkWebsite
                                  ? "#FA4B52"
                                  : "",
                              borderRadius: labelSmoue ? "7px" : "",
                              transition: "background 0.3s ease-in-out",
                              cursor: "pointer",
                            }}
                          >
                            {index + 1}
                          </span>
                        );
                      } else {
                        return null;
                      }
                    })
                  : null}
              </div>
              <div
                className={`pagination-arrow-${
                  response.next ? "right" : "left"
                } ${
                  activeArrow === (response.previous ? "left" : "right")
                    ? "active"
                    : ""
                } `}
                onClick={() => {
                  if (response.next) {
                    setAllInfluencersUrl(response.next);
                    setActiveArrow("left");
                    setActivePage(activePage + 1);
                  }
                }}
                style={
                  labelSmoue && response.next
                    ? {
                        borderRadius: "7px",
                        color: "#E0E0E0",
                        background:
                          "linear-gradient(135deg, rgb(162, 32, 32,.9) 0%, rgb(152, 31, 33,.9) 12%, rgb(148, 30, 36,.9) 23%, rgb(139, 29, 37,.9) 32%, rgb(130, 28, 39,.9) 39%, rgb(125, 28, 41,.9) 45%, rgb(115, 28, 42,.9) 49%, rgb(105, 27, 45,.9) 52%, rgb(92, 25, 45,.9) 56%, rgb(82, 25, 48,.9) 60%, rgb(67, 24, 49,.9) 68%, rgb(46, 22, 49,.9) 81%, rgb(3, 23, 53,.9) 100%)",
                      }
                    : labelSmoue && !response.next
                    ? {
                        backgroundColor: "rgba(255,255,255,43%)",
                        borderRadius: "10px",
                        color: "white",
                      }
                    : (millionWebsite && !response.next) ||
                      (takWebsite && !response.next)
                    ? { background: "#1F1F1F", color: "white" }
                    : (transWebsite && !response.next) ||
                      (sharkWebsite && !response.next) ||
                      (fadaaWebsite && !response.next)
                    ? { background: "#1F1F1F", color: "white" }
                    : sharkWebsite && response.next
                    ? {
                        color: "white",
                        background: "#FA4B52",
                      }
                    : fadaaWebsite && response.next
                    ? {
                        color: "white",
                        background: "#7444BD",
                      }
                    : {}
                }
              >
                <SlArrowLeft />
              </div>
            </div>
          )}
        </div>
      </div>

      <StayInContact />
      {renderFooter()}
    </Fragment>
  );
};

export default AllInfluencers;
