import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SmouFooter from "../../smou/smou-footer/SmouFooter";
import Footer from "../footer/Footer";
import InfuFooter from "../infu-footer/InfuFooter";
import SmouNavbar from "../../smou/smou-components/smou-navbar/SmouNavbar";
import NavBar from "../NabBar/NavBar";
import rightCheck from "../../assets/images/right-check.png";
import wrongCheck from "../../assets/images/wrong-check.png";
import "./qr-check.css";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { getApiUrl } from "../../helpers";
import authHeader from "../../services/auth-header";
import axios from "axios";

const QrCheck = ({ setId, setQrRouting }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [right, setRight] = useState(true);
  const [success, setSuccess] = useState("تمت الزياره بنجاح");

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
  };

  var btnDarb;
  var labelSmoue;
  var infuFoot;
  var millionWebsite;
  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.SMOUE:
      btnDarb = false;
      labelSmoue = true;
      infuFoot = false;

      break;

    case DOMAINS.DARB:
      btnDarb = true;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      break;
    case DOMAINS.INFU:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = true;
      break;
    case DOMAINS.MILLION:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = true;
      break;

    default:
      btnDarb = false;
      labelSmoue = false;
      infuFoot = false;
      millionWebsite = false;
      break;
  }
  function renderFooter() {
    if (labelSmoue) {
      return <SmouFooter />;
    } else if (infuFoot) {
      return <InfuFooter />;
    } else {
      return <Footer />;
    }
  }
  useEffect(() => {
    renderFooter();
  }, []);

  const influencerCheckUrl = getApiUrl(
    `/api/brands/me/check-influencer/${id}/`
  );

  const getInfluencerInfo = async () => {
    try {
      await axios
        .get(influencerCheckUrl, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          console.log("response");
          setSuccess("تمت الزياره بنجاح");
        });
    } catch (error) {
      console.log(error.response.status);
      setLoading(false);
      if (error.response && error.response.status === 401) {
        navigate("/login");
        setQrRouting(true);
        setId(id);
        localStorage.removeItem("brand");
      }
      if (error.response && error.response.status === 404) {
        setRight(false);
        setSuccess("هذا المؤثر لا ينتمي للعلامه التجاريه الخاصه بك");
      }
      if (error.response && error.response.status === 500) {
        setRight(false);
        setSuccess("قم بإعادة مسح ال QR مرة أخرى ");
      }
    }
  };

  useEffect(() => {
    getInfluencerInfo();
  }, []);

  return (
    <Fragment>
      {labelSmoue ? <SmouNavbar /> : <NavBar />}

      <div
        className=" qr-page-parent"
        style={{ backgroundColor: millionWebsite ? "black" : "" }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="qr-page">
            <div className="right-check-parent">
              <img
                src={right ? rightCheck : wrongCheck}
                alt=""
                width=""
                height=""
                className="right-check-child"
              />
            </div>
            <div className="qr-titles">
              <h3 style={{ color: millionWebsite ? "white" : "" }}>
                {success}
              </h3>
              <p style={{ color: millionWebsite ? "white" : "" }}>
                اكتشف المزيد من المؤثرين ليساعدوك في التسويق لعلامتك التجاريه من
                خلال موقعنا
              </p>
            </div>
            <div className="btn-parent-more">
              <button
                style={
                  btnDarb
                    ? {
                        background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                        color: "white",
                        border: "none",
                      }
                    : millionWebsite
                    ? { color: "black" }
                    : {}
                }
                className="btn btn-show-more"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });

                  navigate("/");
                }}
              >
                العودة للصفحة الرئيسية
              </button>
            </div>
          </div>
        )}
      </div>

      {renderFooter()}
    </Fragment>
  );
};

export default QrCheck;
