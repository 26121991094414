import React, { useRef } from "react";
import { BsArrowDown } from "react-icons/bs";
import Title from "../title/Title";
import NavBar from "../NabBar/NavBar";
import "./hero.css";
import azzrkHeroBack from "../../assets/images/azzrk-hero-back.webp";
import mardodHeroBack from "../../assets/images/mardod-hero-back.webp";
import darbHeroBack from "../../assets/images/darb-hero-back.webp";
import waraqaHeroBack from "../../assets/images/waraqa-hero-back.webp";
import atharHeroBack from "../../assets/images/athar-hero-back.png";
import sahmHeroBack from "../../assets/images/sahm-hero-back.png";
import khatwaHeroBack from "../../assets/images/khatwa-hero-back.png";
import rawnqHeroBack from "../../assets/images/rawnq-hero-back.png";
import basmaHeroBack from "../../assets/images/basma-hero-back.png";
import millionHeroBack from "../../assets/images/million-hero-back.png";
import takHeroBack from "../../assets/images/tak-hero-back.png";
import transHeroBack from "../../assets/images/trans-hero-back.png";
import sharkHeroBack from "../../assets/images/shark-hero-back.png";
import roquaHeroBack from "../../assets/images/roqua-hero-back.png";
import mshorHeroBack from "../../assets/images/mshor-hero-back.png";
import fadaaHeroBack from "../../assets/images/fada-hero-back.png";
import azzrkHeroImg from "../../assets/images/azzrk-hero-img.webp";
import mardodHeroImg from "../../assets/images/mardod-hero-img.webp";
import darbHeroImg from "../../assets/images/darb-hero-img.webp";
import warqaHeroImg from "../../assets/images/warqa-hero-img.webp";
import atharHeroImg from "../../assets/images/athar-hero-img.png";
import sahmHeroImg from "../../assets/images/sahm-hero-img.png";
import khatwaHeroImg from "../../assets/images/khatwa-hero-img.png";
import rawnqHeroImg from "../../assets/images/rawnq-hero-img.png";
import basmaHeroImg from "../../assets/images/basma-hero-img.png";
import millionHeroImg from "../../assets/images/million-hero-img.png";
import takHeroImg from "../../assets/images/tak-hero-img.png";
import transHeroImg from "../../assets/images/trans-hero-img.png";
import sharkHeroImg from "../../assets/images/shark-hero-img.png";
import roquaHeroImg from "../../assets/images/roqua-hero-img.png";
import mshorHeroImg from "../../assets/images/mshor-hero-img.png";
import fadaaHeroImg from "../../assets/images/fada-hero-img.png";
import azzrkSubHeroBack from "../../assets/images/azzrk-subhero-back.webp";
import mardodSubHeroBack from "../../assets/images/mardod-subhero-back.webp";
import darbSubHeroBack from "../../assets/images/darb-subhero-back.webp";
import warqaSubHeroBack from "../../assets/images/warqa-subhero-back.webp";
import atharSubHeroBack from "../../assets/images/athar-subhero-back.png";
import sahmSubHeroBack from "../../assets/images/sahm-subhero-back.png";
import khatwaSubHeroBack from "../../assets/images/khatwa-subhero-back.png";
import rawnqSubHeroBack from "../../assets/images/rawnq-subhero-back.png";
import basmaSubHeroBack from "../../assets/images/basma-subhero-back.png";
import millionSubHeroBack from "../../assets/images/million-subhero-back.png";
import takSubHeroBack from "../../assets/images/tak-subhero-back.png";
import transSubHeroBack from "../../assets/images/trans-subhero-back.png";
import roquaSubHeroBack from "../../assets/images/roqua-subhero-back.png";
import mshorSubHeroBack from "../../assets/images/mshor-subhero-back.png";

const Hero = () => {
  const scrollDivRef = useRef();

  function handleScroll() {
    if (scrollDivRef.current)
      scrollDivRef.current.scrollIntoView({ behavior: "smooth" });
  }

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFO: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var heroBackground;
  var heroImg;
  var subHeroBack;
  var whoUs;
  var heroTitle;
  var heroContent;
  var btnContent;
  var warqaPg;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var sahmWebsite;
  var fadaaWebsite;
  var basmaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      heroBackground = azzrkHeroBack;
      heroImg = azzrkHeroImg;
      subHeroBack = azzrkSubHeroBack;
      heroTitle = "كن مستعد";
      heroContent =
        " جميع الأضواء عليك الآن نسلط عليك الأضواء لتلمع في سماء المشاهير وتصبح نجم ساطع في عالم الأعمال";
      whoUs =
        "خدمة الإنفلونسر هى خدمة جديدة ومميزة مُقدمة لك من منصة أزرق لتسهل عليك التواصـل مع المشاهير واختيارهم بناءً على تقييمات وترشيحات تساعدك لتتمكن من الوصول لأكبر عدد من المتابعين المهتمين بما تقدمه لتحقق أوسع إنتشار وتزيد الوعي بعلامتك التجارية وتحصل على المزيد من التفاعل على المنصات المختلفة .";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.MARDOD:
      heroBackground = mardodHeroBack;
      heroImg = mardodHeroImg;
      subHeroBack = mardodSubHeroBack;
      heroTitle = "كن مستعد";
      heroContent =
        " جميع الأضواء عليك الآن نسلط عليك الأضواء لتلمع في سماء المشاهير وتصبح نجم ساطع في عالم الأعمال";
      whoUs = `مردود منصة متخصصة في التسويق الإلكترونية و استشارات المتاجر الالكترونية داخل المملكة العربية السعودية تقدم خدماتها بالاعتماد على فريق العمل المتخصص في مجال التسويق الرقمي، ويمتلك فريق العمل خبرة أكثر من 10 أعوام وهذا ما يجعلهم يعملون بإبداع ويساعدونك في سباق التطور التكنولوجي السريع تعد خدمة المشاهير من أحدث الاتجاهات في مجال التسويق الإلكتروني، وهي تقنية حديثة تعتمد على استخدام شخصيات مشهورة ومؤثرة لتعزيز وتسويق المنتجات والخدمات. إن استخدام المشاهير كجزء من استراتيجيتك التسويقية يكون له تأثير كبير على نجاح عملك ونموه بصورة مذهلة. نحن ندرك أهمية خدمة المشاهير وكيفية استغلالها بشكل فعال. نقوم بتحليل احتياجات عملك واهدافك التسويقية لتحديد المشاهير المناسبين لشركتك`;
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.DARB:
      heroBackground = darbHeroBack;
      heroImg = darbHeroImg;
      subHeroBack = darbSubHeroBack;
      heroTitle = "أهلا بك في درب ";
      heroContent =
        "وجهتك لدخول عالم التجارة الإلكترونية والتسويق الرقمي والتسويق بالمؤثرين";
      whoUs =
        " درب هي منصة رقمية سعودية متكاملة الخدمات في مجال التسويق الالكتروني ووجهتك لدخول عالم التجارة الالكترونية والتسويق بواسطة المؤثرين ولأننا نسعى دائمًا لتحسين أساليبنا وتجربة استراتيجيات تسويقية جديدة لتواكب التطورات الحالية في السوق وتحقيق نتائج إيجابية وملموسة لعملائنا،  نجمع لك أكبر المؤثرين في كل المجالات عبر منصات  التواصل الاجتماعي ونقدم لك الحلول التسويقية التي تساعدك على نمو علامتك التجارية و توليد مبيعات  عبر الإنترنت من خلال اختيار المبدعين والمؤثرين والمشاهير المناسبين لنشاطك التجاري وعلامتك  التجارية .";
      btnContent = "اعرف عنا";
      warqaPg = false;
      break;

    case DOMAINS.WARAQA:
      heroBackground = waraqaHeroBack;
      heroImg = warqaHeroImg;
      subHeroBack = warqaSubHeroBack;
      whoUs =
        "ورقة هي منصة متخصصة في الحلول التسويقية للمتاجر الإلكترونية في المملكة العربية السعودية.. خدمات التسويق عبر المؤثرين هي عبارة عن حلول شاملة للشركات لتحسين تسويق منتجاتهم وخدماتهم عن طريق استخدام المؤثرين في وسائل التواصل الاجتماعي و تتضمن خدمات التسويق عبر المؤثرين تحديد المؤثرين المناسبين وتصميم الحملات الإعلانية عن طريقهم وتتبع النتائج. تتيح خدمات التسويق عبر المؤثرين فوائد كثيرة للشركات حيث تساعد في زيادة الوعي بالعلامة  التجارية ومضاعفة الأرباح وتحسين تواجد العلامة التجارية في الأسواق المختلفة ";
      warqaPg = true;
      heroTitle = "حياك الله";
      heroContent = "نسعد برؤيتك في موقعنا";
      btnContent = "تعرف علينا";
      break;

    case DOMAINS.ATHAR:
      heroBackground = atharHeroBack;
      heroImg = atharHeroImg;
      subHeroBack = atharSubHeroBack;
      heroTitle = "حياك الله";
      heroContent = "نسعد برؤيتك في موقعنا";
      whoUs =
        "مسوقون محترفون، نعلَم أن الأثر هو الباقِ، ولهذا جئنا بالمؤثرين من كل أنحاء المملكة ليقوموا بترويج نشاطك التجاري، ليظهر للجميع في كل مكان، لأن دور المؤثر هو جذب أكبر جمهور ممكن، فلهذا اخترنا أفضل المؤثرين بناءًا على عدد متابعيهم ومدى ثقة الجمهور فيهم. انضم لنا الآن وشاهد تجارتك تزدهر.";
      btnContent = "تعرف علينا";
      warqaPg = false;
      atharWebsite = true;
      break;

    case DOMAINS.SAHM:
      heroBackground = sahmHeroBack;
      heroImg = sahmHeroImg;
      subHeroBack = sahmSubHeroBack;
      sahmWebsite = true;
      heroTitle = "أظهر منتجات متجرك في سماء النجوم..";
      heroContent = "";
      whoUs =
        "التسويق عن طريق المشاهير هي استراتيجية تسويقية تعتمد على تعاون العلامات التجارية مع شخصيات مؤثرة على وسائل التواصل الاجتماعي لتعزيز منتجاتها أو خدماتها. كما يسمح للعلامات التجارية بالوصول إلى جمهور جديد من خلال شخصيات معروفة وموثوق بها على الإنترنت. لأن متابعين المشاهير يتأثرون بقرارتهم وتوصياتهم  ولا يعني بالضرورة عدد المتابعين الكبير للمؤثرين نجاح أهدافك المخططة للتسويق عبر المؤثرين. لأنه يتم إختيار المؤثرين بناءًا على القاعدة جماهيرية على منصات التواصل الاجتماعي، ونوعية جمهورهم المستهدف وشعبيتهم في مجال معين.";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.KHATWA:
      heroBackground = khatwaHeroBack;
      heroImg = khatwaHeroImg;
      subHeroBack = khatwaSubHeroBack;
      heroTitle = "خطوة..";
      heroContent = " لحاضرك اللامع بين النجوم";
      whoUs =
        "خطوة هي شركة تسويق تقدم حلول تسويقية مختلفة لمتجرك الإلكتروني  بما فيها التسويق عبر المؤثرين.   نبدأ بدراسة شخصية المؤثر وجمهوره المستهدف لضمان التوافق مع رؤيتك وأهدافك.  بالإضافة إلى التفاوض والاتفاق على شروط المحتوى المراد تقديمه مع المؤثر، بما يخدم مصلحة متجرك ويراعي تطلعاتك واحتياجات جمهورك المستهدف.";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.RAWNQ:
      heroBackground = rawnqHeroBack;
      heroImg = rawnqHeroImg;
      subHeroBack = rawnqSubHeroBack;
      heroTitle = "رونق .. ";
      heroContent = "حيث يتجلى الجمال والأداء في عالم التجارة الإلكترونية";
      whoUs =
        "نحن هنا لنمنح حياة جديدة لمتجرك حيث تقدم الشركة خدمات تصميم وتطوير المواقع والمتاجر الإلكترونية وخدمات التسويق من خلال المؤثرين  حيث نكون دليلك في عالم التجارة الإلكترونية";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.BASMA:
      heroBackground = basmaHeroBack;
      heroImg = basmaHeroImg;
      subHeroBack = basmaSubHeroBack;
      heroTitle = "من بين خطوط الكفوف ..";
      heroContent = "غير النجاح ما نشوف";
      whoUs =
        "بصماتنا تختلف عن غيرنا ، لكن نجاحنا شي واحد ما نختلف عليه  أهلًا فيك مع مؤسسة بصمة لإدارة وإنشاء المتاجر الإلكترونية وخدمات التسويق من خلال المؤثرين  حيث تكون مفتاح لنجاح يلازمك مثل خطوط يدك";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      basmaWebsite = true;
      break;

    case DOMAINS.MILLION:
      millionWebsite = true;
      heroBackground = millionHeroBack;
      heroImg = millionHeroImg;
      subHeroBack = millionSubHeroBack;
      heroTitle = "";
      heroContent = "";
      whoUs =
        "بصماتنا تختلف عن غيرنا ، لكن نجاحنا شي واحد ما نختلف عليه  أهلًا فيك مع مؤسسة وان مليون لإدارة وإنشاء المتاجر الإلكترونية وخدمات التسويق من خلال المؤثرين  حيث تكون مفتاح لنجاح يلازمك مثل خطوط يدك";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.TAK:
      millionWebsite = false;
      takWebsite = true;
      heroBackground = takHeroBack;
      heroImg = takHeroImg;
      subHeroBack = takSubHeroBack;
      heroTitle = "هلا فيكم فى عالم تاك ،";
      heroContent =
        "حيث يختلط الإبداع بالتسويق الرقمي ، لنصبح شركائك في رحلة النجاح و التطوير فى عالم التجارة الإلكترونية";
      whoUs =
        "تاك هي واحدة من الشركات الرائدة في مجال التسويق الإلكتروني بالمملكة ، برؤية واضحة منذ البداية ، و هي أن نصبح روادًا في مجال التسويق الإلكتروني ، حتى نُلهم عُملائنا إبداعًا لا حدود له و نُرسي معايير جديدة للتميز في عالم التسويق لأن هدفنا هو اطلاق صاروخ تسويقي ينطلق بعلامتك التجارية نحو آفاق جديدة من النجاح تجعلك دائماً رمزًا للابتكار في عالم التسويق الإلكتروني و تساعدك على تحقيق أرباح هائلة و اكتساب عملاء مخلصين لمشروعك من خلال استراتيجيات تسويقية ذكية و مبتكرة و فعالة .";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.TRANS:
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      heroBackground = transHeroBack;
      heroImg = transHeroImg;
      subHeroBack = transSubHeroBack;
      heroTitle = "بفكر مختلف تماماً";
      heroContent =
        "و في ظل التطور العظيم للذكاء الاصطناعي قررنا في مؤسسة ترانزيشن مزج الإبداع البشري مع أدوات الذكاء الاصطناعي لنضمن لعملائنا إضافة قيمة حقيقية لتجارتهم الإلكترونية      ";
      whoUs =
        "ترانزيشن هي واحدة من الشركات السباقة في مجال التسويق الإلكتروني بالمملكة ، حيث نقود تطور التجارة الإلكترونية بما يتماشى مع رؤية المملكة 2030 من خلال دمج التكنولوجيا الذكية مع الإبداع البشري لخلق استراتيجيات تسويقية مبتكرة لعملائنا حيث نسعى لإعادة هيكلة سوق التجارة الإلكترونية و  صياغته فى ثوب الذكاء الإصطناعي الحديث ليمتد مئات السنين نكون فيها دائماً بعون الله مواكبين لكل تطور و كل جديد      ";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.SHARK:
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      heroBackground = sharkHeroBack;
      heroImg = sharkHeroImg;
      subHeroBack = transSubHeroBack;
      heroTitle = "اجعل متجرك ذو صيت بكل أنحاء المملكة ";
      heroContent = "";
      whoUs =
        "نحن خبراء في التسويق الإلكتروني، نقدم لكم خدمات مميزة للتسويق عبر المشاهير، حيث نعمل مع نخبة من أهم المؤثرين في المملكة العربية السعودية، المختارين بعناية بناءً على دراسات شاملة لتأثيرهم على المنصات المختلفة. يمكن للمؤثرين تقديم علامتك التجارية للجمهور المستهدف وجعل اسمك مشهورًا بين شرائح واسعة من العملاء ، نحن ندرك أن الأثر هو الباقي، ولذلك اخترنا المؤثرين بعناية بناءً على عدد متابعيهم ومدى ثقة الجمهور فيهم، لضمان أن يصل نشاطك التجاري لأكبر عدد ممكن من الجمهور ويحقق تأثيرًا واسعًا؛ نحن نختار المشاهير بناءً على تقييمات وترشيحات موثوقة، مما يساعدك في تحقيق انتشار أوسع وزيادة الوعي بعلامتك التجارية على المنصات المختلفة.      ";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.Roqua:
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      heroBackground = roquaHeroBack;
      heroImg = roquaHeroImg;
      subHeroBack = roquaSubHeroBack;
      heroTitle = "اهلا بك";
      heroContent = "من جديد في موقعنا";
      whoUs =
        "بصماتنا تختلف عن غيرنا ، لكن نجاحنا شي واحد ما نختلف عليه  أهلًا فيك مع مؤسسة رؤية لإدارة وإنشاء المتاجر الإلكترونية وخدمات التسويق من خلال المؤثرين  حيث تكون مفتاح لنجاح يلازمك مثل خطوط يدك";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.MSHOR:
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      heroBackground = mshorHeroBack;
      heroImg = mshorHeroImg;
      subHeroBack = mshorSubHeroBack;
      heroTitle = "اهلا بك";
      heroContent = "من جديد في موقعنا";
      whoUs =
        "نصلكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، لا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      break;

    case DOMAINS.FADAA:
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      heroBackground = fadaaHeroBack;
      heroImg = fadaaHeroImg;
      heroTitle = "أهلاً بك من جديد في فضاء";
      heroContent = "احصل الآن على مؤثرك وألمع في فضائك الخاص!";
      whoUs ="وسط فضاء الشركات والمنافسين يبحث أصحاب المتاجر عن فضائهم الخاص الذي من خلاله تيتح لهم فرصة الإنتشار، وأن يلمع بريقهم وسط كل هذا الكم من التنافس لذا مرحباً بك في فضاء! فضاء هي مؤسسة تسويق إلكتروني..تحوي مستشاريين تسويقين متخصصين في إدارة المتاجر الإلكترونية يقدمون لك خدمات تسويقية مميزة والتي من أكثرها فاعلية وإنتشاراً لعلامتك التجارية هي خدمة الأنفلونسر! من خلال تلك الخدمة تستطيع الوصول إلى كل المتابعين الملائمين لعلامتك التجارية وكسب ثقتهم عن طريق الإنفلونسر بكل سهولة! إذا كنت مستعد لـ الإنتشار في لمح البصر لا تترد في التواصل مع فضاء!";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
      fadaaWebsite = true;
      break;

    default:
      millionWebsite = false;
      heroBackground = azzrkHeroBack;
      heroImg = azzrkHeroImg;
      subHeroBack = azzrkSubHeroBack;
      heroTitle = "كن مستعد";
      heroContent =
        " جميع الأضواء عليك الآن نسلط عليك الأضواء لتلمع في سماء المشاهير وتصبح نجم ساطع في عالم الأعمال";
      whoUs =
        "خدمة الإنفلونسر هى خدمة جديدة ومميزة مُقدمة لك من منصة أزرق لتسهل عليك التواصـل مع المشاهير واختيارهم بناءً على تقييمات وترشيحات تساعدك لتتمكن من الوصول لأكبر عدد من المتابعين المهتمين بما تقدمه لتحقق أوسع إنتشار وتزيد الوعي بعلامتك التجارية وتحصل على المزيد من التفاعل على المنصات المختلفة .";
      btnContent = "استكشف خدمتنا ";
      warqaPg = false;
  }

  return (
    <div className="hero">
      <NavBar />
      <div
        className={millionWebsite ? "million-website-hero top" : "top"}
        style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundColor: sahmWebsite
            ? "#5E384A"
            : fadaaWebsite
            ? "#170E26"
            : basmaWebsite
            ? "#2E316F"
            : "",
        }}
      >
        <div className="container">
          <div className="text">
            <h1
              className="hero-title"
              style={{ color: atharWebsite ? "white" : " " }}
            >
              {heroTitle}
            </h1>
            <h1>{heroContent}</h1>
          </div>
          <div className="scroll" onClick={handleScroll}>
            <p>{btnContent}</p>
            <div className="icon">
              <BsArrowDown />
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom"
        ref={scrollDivRef}
        id="who-us"
        style={
          warqaPg
            ? {
                backgroundImage: `url(${warqaSubHeroBack})`,
                backgroundPosition: "bottom",
              }
            : fadaaWebsite
            ? { backgroundColor: "#170E26" }
            : { backgroundImage: `url(${subHeroBack})` }
        }
      >
        <Title color={atharWebsite ? "black" : "white"} text="من نحن" />
        <div className="container who-we-are">
          <div className={warqaPg ? "whoUsParentDiv row gap-5" : "row gap-5"}>
            <div className="col whoUsParent">
              <p
                style={{
                  fontSize: "20px",
                  lineHeight: "41px",
                  padding: "30px 30px 30px 20px",
                  borderRadius: "72px",
                  margin: "0",
                  color: atharWebsite ? "black" : "white",
                }}
              >
                {whoUs}
              </p>
            </div>
            <div className="col warqaDiv">
              <div
                className="image"
                style={{
                  width: sharkWebsite ? "80%" : "",
                  margin: sharkWebsite ? "auto" : "",
                }}
              >
                <img
                  src={heroImg}
                  alt=""
                  style={{ borderRadius: millionWebsite ? 0 : "" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
