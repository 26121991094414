import React, { Fragment, useState, useEffect } from "react";
import "./change-password.css";
import authService from "../../services/auth-services";
import validator from "validator";
import Swal from "sweetalert2";
import LoadingButton from "../loading-button/LoadingButton";
import { useNavigate } from "react-router-dom";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

const ChangePasswordSetting = () => {
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [passToggelhint, setpassToggelhint] = useState(false);
  const [checklengthPassword, setchecklengthPass] = useState("");
  const [checkuppercase, setcheckUppercase] = useState("");
  const [checkLowercase, setcheckLowercase] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [checkDigit, setcheckDigit] = useState("");
  const [checkSpecialChar, setcheckSpecialChar] = useState("");
  const [isFocusedConfirm, setIsFocusedConfirm] = useState(false);
  const [hintoldPass, sethintoldPass] = useState(false);
  const [checkpass, setcheckpass] = useState(false);
  const [focusoldPAss, setfocusoldPass] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [toogelaccount, settoggelaccount] = useState(false);
  const [tooglePassword1, setTooglePassword1] = useState(true);
  const [tooglePassword2, setTooglePassword2] = useState(true);
  const [tooglePassword3, setTooglePassword3] = useState(true);
  const [eyeAppear1, setEyeAppear1] = useState(false);
  const [eyeAppear2, setEyeAppear2] = useState(false);
  const [eyeAppear3, setEyeAppear3] = useState(false);
  const navigate = useNavigate();

  const isValidPassword = (newPassword) => {
    const options = {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
      returnScore: false,
    };
    return validator.isStrongPassword(newPassword, options);
  };

  useEffect(() => {
    setchecklengthPass(newPassword.length >= 8 ? true : false);
    setcheckUppercase(/[A-Z]+/.test(newPassword));
    setcheckLowercase(/[a-z]+/.test(newPassword));
    setcheckDigit(/[0-9]+/.test(newPassword));
    setcheckSpecialChar(/[^A-Za-z0-9]+/.test(newPassword));
  }, [newPassword]);

  useEffect(() => {}, [oldPassword, newPassword, confirmNewPassword]);

  useEffect(() => {
    if (newPassword === confirmNewPassword) {
      setIsFocusedConfirm(false);
    } else {
      setIsFocusedConfirm(true);
    }
  }, [confirmNewPassword]);

  useEffect(() => {
    setcheckpass(oldPassword.length > 0 ? false : true);
  }, [oldPassword]);

  const handeloldPass = () => {
    sethintoldPass(false);
    setfocusoldPass(true);
    setEyeAppear1(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setpassToggelhint(false);
    setEyeAppear2(true);
  };

  const handleFocusConfirm = () => {
    setIsFocusedConfirm(true);
    setEyeAppear3(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //password
    if (!newPassword) {
      setpassToggelhint(true);
      setIsFocused(false);
    }
    if (!oldPassword) {
      sethintoldPass(true);
      setfocusoldPass(false);
    }
    if (
      isValidPassword(newPassword) &&
      newPassword === confirmNewPassword &&
      oldPassword.length > 1
    ) {
      try {
        setLoadingSave(true);
        await authService.userChangePassword(oldPassword, newPassword).then(
          (response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "تم تغيير كلمة المرور بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoadingSave(false);
            setconfirmNewPassword("");
            setnewPassword("");
            setoldPassword("");
            setfocusoldPass(false);
            setIsFocused(false);
            setpassToggelhint(false);
            settoggelaccount(false);
          },
          (error) => {
            setLoadingSave(false);
            if (error.response && error.response.status === 401) {
              // Handle 401 error here
              localStorage.removeItem("brand");
              navigate("/login");
            }
            if (
              error.response.data.old_password[0] ===
              "Old password is not correct"
            ) {
              settoggelaccount(true);
            }
          }
        );
      } catch (err) {}
    }
  };

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var btnDarb;
  var labelSmoue;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      btnDarb = false;
      break;

    case DOMAINS.MARDOD:
      btnDarb = false;
      break;

    case DOMAINS.SMOUE:
      labelSmoue = true;
      break;

    case DOMAINS.DARB:
      btnDarb = true;
      break;

    case DOMAINS.WARAQA:
      btnDarb = false;
      break;

    case DOMAINS.ATHAR:
      btnDarb = false;
      atharWebsite = true;
      break;

    case DOMAINS.MILLION:
      btnDarb = false;
      millionWebsite = true;
      break;

    case DOMAINS.TAK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.MSHOR:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = true;
      break;

    case DOMAINS.FADAA:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      btnDarb = false;
      millionWebsite = false;
      takWebsite = false;
      break;
  }
  return (
    <Fragment>
      <div className="Change-password-setting">
        <form onSubmit={handleSubmit}>
          {toogelaccount && (
            <div className="alert alert-danger hintMsg" role="alert">
              كلمة المرور الحالية غير صحيحة
            </div>
          )}
          <div>
            <label
              htmlFor="current-password"
              style={
                labelSmoue ||
                takWebsite ||
                transWebsite ||
                sharkWebsite ||
                fadaaWebsite
                  ? { color: "#fff" }
                  : atharWebsite || mshorWebsite
                  ? { color: "black" }
                  : {}
              }
            >
              كلمة السر الحاليه
            </label>
            <div className="input-password-parent">
              <input
                type={tooglePassword1 ? "password" : "text"}
                name="current-password"
                id="current-password"
                placeholder="كلمة السر الحاليه"
                value={oldPassword}
                onChange={(e) => setoldPassword(e.target.value)}
                onFocus={handeloldPass}
                style={
                  labelSmoue
                    ? { backgroundColor: "rgba(255,255,255,50%)" }
                    : millionWebsite
                    ? { background: "black", color: "white" }
                    : takWebsite
                    ? {
                        background: "black",
                        color: "white",
                        border: "1px solid #FF0593",
                      }
                    : transWebsite
                    ? {
                        border: "1px solid #02C6C6",
                        background: "black",
                        color: "white",
                      }
                    : sharkWebsite
                    ? {
                        border: "1px solid #B559E8",
                        background: "#1F1F1F",
                        color: "white",
                      }
                    : atharWebsite
                    ? {
                        border: "1px solid #F15A3A",
                        background: "transparent",
                        color: "black",
                      }
                    : fadaaWebsite
                    ? {
                        border: "1px solid #7444BD",
                        background: "transparent",
                        color: "white",
                      }
                    : {}
                }
              />
              {eyeAppear1 && (
                <div
                  className="eye-password-child"
                  onClick={() => setTooglePassword1(!tooglePassword1)}
                  style={{
                    color:
                      millionWebsite || fadaaWebsite
                        ? "white"
                        : takWebsite || transWebsite || sharkWebsite
                        ? "rgba(171, 171, 171, 0.55)"
                        : "",
                  }}
                >
                  {tooglePassword1 ? <VscEye /> : <VscEyeClosed />}
                </div>
              )}
            </div>
            {hintoldPass && <span className="spanhint">مطلوب</span>}
            {focusoldPAss && checkpass && (
              <span className="spanhint">مطلوب</span>
            )}
          </div>

          <div>
            <label
              htmlFor="new-password"
              style={
                labelSmoue ||
                takWebsite ||
                transWebsite ||
                sharkWebsite ||
                fadaaWebsite
                  ? { color: "#fff" }
                  : atharWebsite || mshorWebsite
                  ? { color: "black" }
                  : {}
              }
            >
              كلمة السر الجديده
            </label>
            <div className="input-password-parent">
              <input
                type={tooglePassword2 ? "password" : "text"}
                name="new-password"
                id="new-password"
                placeholder="كلمة السر الجديده"
                value={newPassword}
                onChange={(e) => setnewPassword(e.target.value)}
                onFocus={handleFocus}
                style={
                  labelSmoue
                    ? { backgroundColor: "rgba(255,255,255,50%)" }
                    : millionWebsite
                    ? { background: "black", color: "white" }
                    : takWebsite
                    ? {
                        background: "black",
                        color: "white",
                        border: "1px solid #FF0593",
                      }
                    : transWebsite
                    ? {
                        border: "1px solid #02C6C6",
                        background: "black",
                        color: "white",
                      }
                    : sharkWebsite
                    ? {
                        border: "1px solid #B559E8",
                        background: "#1F1F1F",
                        color: "white",
                      }
                    : atharWebsite
                    ? {
                        border: "1px solid #F15A3A",
                        background: "transparent",
                        color: "black",
                      }
                    : fadaaWebsite
                    ? {
                        border: "1px solid #7444BD",
                        background: "transparent",
                        color: "white",
                      }
                    : {}
                }
              />
              {eyeAppear2 && (
                <div
                  className="eye-password-child"
                  onClick={() => setTooglePassword2(!tooglePassword2)}
                  style={{
                    color:
                      millionWebsite || fadaaWebsite
                        ? "white"
                        : takWebsite || transWebsite || sharkWebsite
                        ? "rgba(171, 171, 171, 0.55)"
                        : "",
                  }}
                >
                  {tooglePassword2 ? <VscEye /> : <VscEyeClosed />}
                </div>
              )}
            </div>
            <div className="password-hints">
              {passToggelhint && <span className=" spanhint">مطلوب</span>}
              {isFocused && !checklengthPassword && (
                <span className=" spanhint">
                  يجب ألا تقل كلمة المرور عن 8 أحرف,
                </span>
              )}
              {isFocused && !checkuppercase && (
                <span className=" spanhint">حرف كبير,</span>
              )}
              {isFocused && !checkLowercase && (
                <span className="spanhint">&nbsp;&nbsp;حرف صغير,</span>
              )}

              {isFocused && !checkDigit && (
                <span className=" spanhint">رقم,</span>
              )}
              {isFocused && !checkSpecialChar && (
                <span className=" spanhint">رمز</span>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="confirm-password"
              style={
                labelSmoue || takWebsite || transWebsite || sharkWebsite
                  ? { color: "#fff" }
                  : atharWebsite || mshorWebsite
                  ? { color: "black" }
                  : {}
              }
            >
              تأكيد كلمه السر
            </label>
            <div className="input-password-parent">
              <input
                type={tooglePassword3 ? "password" : "text"}
                name="confirm-password"
                id="confirm-password"
                placeholder="تأكيد كلمه السر"
                value={confirmNewPassword}
                onChange={(e) => setconfirmNewPassword(e.target.value)}
                onFocus={handleFocusConfirm}
                style={
                  labelSmoue
                    ? { backgroundColor: "rgba(255,255,255,50%)" }
                    : millionWebsite
                    ? { background: "black", color: "white" }
                    : takWebsite
                    ? {
                        background: "black",
                        color: "white",
                        border: "1px solid #FF0593",
                      }
                    : transWebsite
                    ? {
                        border: "1px solid #02C6C6",
                        background: "black",
                        color: "white",
                      }
                    : sharkWebsite
                    ? {
                        border: "1px solid #B559E8",
                        background: "#1F1F1F",
                        color: "white",
                      }
                    : atharWebsite
                    ? {
                        border: "1px solid #F15A3A",
                        background: "transparent",
                        color: "black",
                      }
                    : fadaaWebsite
                    ? {
                        border: "1px solid #7444BD",
                        background: "transparent",
                        color: "white",
                      }
                    :{}
                }
              />
              {eyeAppear3 && (
                <div
                  className="eye-password-child"
                  onClick={() => setTooglePassword3(!tooglePassword3)}
                  style={{
                    color: millionWebsite || fadaaWebsite
                      ? "white"
                      : takWebsite || transWebsite || sharkWebsite
                      ? "rgba(171, 171, 171, 0.55)"
                      : "",
                  }}
                >
                  {tooglePassword3 ? <VscEye /> : <VscEyeClosed />}
                </div>
              )}
            </div>
            {isFocusedConfirm && (
              <span className=" spanhint">كلمة المرور غير متطابقة</span>
            )}
          </div>

          <button
            className="btn"
            type="submit"
            style={
              btnDarb
                ? {
                    background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                    color: "black",
                    border: "none",
                  }
                : labelSmoue
                ? {
                    background: `linear-gradient(to right, rgb(219, 55, 55), transparent 64%), linear-gradient(to left, rgb(0, 39, 98), transparent 50%)`,
                    color: "white",
                    border: "none",
                    borderRadius: "10px",
                    padding: ".8rem",
                  }
                : millionWebsite
                ? { color: "black" }
                : takWebsite
                ? {
                    backgroundColor: "#00F800",
                    borderRadius: "55px",
                    boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                    color: "black",
                  }
                : transWebsite
                ? {
                    color: "black",
                  }
                : sharkWebsite
                ? {
                    backgroundColor: "#FA4B52",
                    color: "white",
                    border: "1px solid #FBFA2D",
                  }
                :fadaaWebsite
                ? {
                    background: "#F5ED0C",
                    color: "black",
                  }
                : {}
            }
          >
            {loadingSave ? <LoadingButton /> : "حفظ التغييرات "}
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default ChangePasswordSetting;
