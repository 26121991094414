import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./nav-bar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import warringIcon from "../../assets/images/warning-icon.webp";
import { getApiUrl } from "../../helpers";
import authHeader from "../../services/auth-header";
import axios from "axios";
import azzrkLogo from "../../assets/images/azzrak-logo.webp";
import mardodLogo from "../../assets/images/mardod-logo-footer.webp";
import atharLogo from "../../assets/images/athar-logo.png";
import darbLogo from "../../assets/images/darb-logo-footer.webp";
import warqaLogo from "../../assets/images/warqa-logo-footer.webp";
import sahmLogo from "../../assets/images/sahm-logo-footer.png";
import khatwaLogo from "../../assets/images/khatwa-logo-footer.png";
import rawnqLogo from "../../assets/images/rawnq-logo-footer.png";
import basmaLogo from "../../assets/images/basma-logo-navbar.png";
import millionLogo from "../../assets/images/million-logo-navbar.png";
import takLogo from "../../assets/images/tak-logo-navbar.png";
import transLogo from "../../assets/images/trans-logo.png";
import sharkLogo from "../../assets/images/shark-logo.png";
import infuLogo from "../../assets/images/infu-logo-navbar.png";
import roquaLogo from "../../assets/images/roqua-logo-navbar.png";
import mshorLogo from "../../assets/images/mshor-logo-navbar.png";
import fadaaLogo from "../../assets/images/fadaa-logo-navbar.png";
import azzrkUser from "../../assets/images/azzrk-user.png";
import mardodUser from "../../assets/images/mardod-user.webp";
import atharUser from "../../assets/images/athar-user.webp";
import darbUser from "../../assets/images/darb-user.webp";
import warqaUser from "../../assets/images/warqa-user.webp";
import sahmUser from "../../assets/images/sahm-user.png";
import khatwaUser from "../../assets/images/khatwa-user.png";
import rawnqUser from "../../assets/images/rawnq-user.png";
import mshorUser from "../../assets/images/mshor-user.png";
import basmaUser from "../../assets/images/basma-user.png";

function NavBar() {
  const [navBg, setNavBg] = useState();
  const [showNavBar, setShowNavBar] = useState(false);
  const [toggleSetting, setToggleSetting] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const [dropDownToogle, setDropDownToogle] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const brand = JSON.parse(localStorage.getItem("brand"));
  useEffect(() => {
    setToggleSetting(brand ? true : false);
  }, [brand]);

  const scrollToPercent = (percent) => {
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    const scrollToPixel =
      (percent / 100) * (document.documentElement.scrollHeight - windowHeight);

    window.scrollTo({ top: scrollToPixel, behavior: "smooth" });
  };

  function handleShowNavbar() {
    setShowNavBar(!showNavBar);
  }

  useEffect(() => {
    if (window.location.pathname === "/") {
      setNavBackground(true);
    }
  }, []);

  const logoutFunc = () => {
    localStorage.removeItem("brand");
    navigate("/login");
  };
  const userInfoUrl = getApiUrl("api/brands/me/profile/");
  const [userInfo, setUserInfo] = useState("");
  const getUserData = () => {
    axios
      .get(userInfoUrl, { headers: authHeader() })
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 error here, such as redirecting the user to the login page
          localStorage.removeItem("brand");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (brand) {
      getUserData();
    }
  }, []);

  var subdomain = window.location.origin;
  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFU: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var logo;
  var user;
  var btnDarb;
  var logoHeight;
  var navControl;
  var millionWebsite;
  var takWebsite;
  var transWebsite;
  var sharkWebsite;
  var atharWebsite;
  var mshorWebsite;
  var fadaaWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      logo = azzrkLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = true;
      navControl = false;
      break;

    case DOMAINS.MARDOD:
      logo = mardodLogo;
      user = mardodUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.DARB:
      logo = darbLogo;
      user = darbUser;
      btnDarb = true;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.WARAQA:
      logo = warqaLogo;
      user = warqaUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.ATHAR:
      logo = atharLogo;
      user = atharUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      atharWebsite = true;
      break;

    case DOMAINS.SAHM:
      logo = sahmLogo;
      user = sahmUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.KHATWA:
      logo = khatwaLogo;
      user = khatwaUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.RAWNQ:
      logo = rawnqLogo;
      user = rawnqUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.BASMA:
      logo = basmaLogo;
      user = basmaUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      break;

    case DOMAINS.INFU:
      logo = infuLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = true;
      navControl = true;
      break;

    case DOMAINS.MILLION:
      logo = millionLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = true;
      break;

    case DOMAINS.TAK:
      logo = takLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = true;
      break;

    case DOMAINS.TRANS:
      logo = transLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      logo = sharkLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.Roqua:
      logo = roquaLogo;
      user = rawnqUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      break;

    case DOMAINS.MSHOR:
      logo = mshorLogo;
      user = mshorUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = true;
      break;

    case DOMAINS.FADAA:
      logo = fadaaLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = false;
      navControl = false;
      millionWebsite = false;
      takWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      mshorWebsite = false;
      fadaaWebsite = true;
      break;

    default:
      logo = azzrkLogo;
      user = azzrkUser;
      btnDarb = false;
      logoHeight = true;
      navControl = false;
      millionWebsite = false;
  }

  const handleLogo = () => {
    // Check the condition
    if (navControl) {
      // Open a new link
      navigate("/");
    } else {
      // Scroll to the top
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleHome = () => {
    // Check the condition
    if (navControl) {
      // Open a new link
      window.open("https://infugram.com", "_self");
    } else {
      // Scroll to the top
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handelAbout = () => {
    if (navControl) {
      // Open a new link
      window.open(
        "https://infugram.com/%d8%a7%d9%86%d9%81%d9%88%d8%ac%d8%b1%d8%a7%d9%85%d8%8c-%d8%a3%d9%83%d8%a8%d8%b1-%d9%85%d9%86%d8%b5%d8%a9-%d8%b9%d8%b1%d8%a8%d9%8a%d8%a9-%d9%84%d8%b1%d8%a8%d8%b7-%d8%a7%d9%84%d9%85%d8%b3%d9%88%d9%82/",
        "_self"
      );
    } else {
      setShowNavBar(false);

      if (location.pathname === "/") {
        const sectionElement = document.querySelector("#who-us");
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        navigate("/");
        scrollToPercent(42);
      }
    }
  };

  function changeNavBg() {
    if (navControl) {
      setNavBg(true);
    } else {
      window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
    }
  }
  window.addEventListener("scroll", changeNavBg);
  useEffect(() => {
    if (navControl) {
      setNavBg(true);
    }
  }, []);
  return (
    <nav
      className={`main-navbar ${
        navBg
          ? `sticky  ${
              btnDarb
                ? `darb-color-bg`
                : fadaaWebsite
                ? `fadaa-color-bg`
                : `color-bg`
            } ${
              takWebsite || transWebsite || sharkWebsite
                ? `tak-color-bg`
                : atharWebsite
                ? "athar-color-bg"
                : `color-bg`
            }`
          : ""
      } ${
        navBackground
          ? "navBack"
          : `no-navBack ${btnDarb ? `darb-navBack` : ``} ${
              takWebsite || sharkWebsite ? `tak-navBack` : ``
            } ${atharWebsite ? `athar-navBack` : ``} ${
              transWebsite || sharkWebsite ? `trans-navBack` : ``
            } ${fadaaWebsite ? `fadaa-navBack` : ``} `
      }`}
      style={{ background: millionWebsite ? "black" : "" }}
    >
      <div
        className="container"
        style={{ padding: millionWebsite ? "1rem" : "" }}
      >
        <div
          className={
            logoHeight
              ? "nav-logo nav-logo-azzrk"
              : "nav-logo nav-logo-websites"
          }
          style={{ width: atharWebsite ? "5%" : "" }}
        >
          <img
            src={logo}
            alt="Azzrk logo"
            onClick={() => {
              handleLogo();
            }}
          />
        </div>
        <div
          className={atharWebsite ? "menu-icon menu-icon-athar" : "menu-icon"}
          onClick={handleShowNavbar}
        >
          {showNavBar ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <div
          className={`nav-btn-parent ${showNavBar && "active"} ${
            takWebsite || transWebsite ? `nav-btn-parent-tak` : ``
          }`}
          style={{ backgroundColor: millionWebsite ? "black" : "" }}
        >
          <ul className="nav-links">
            <li
              onClick={() => {
                handleHome();
              }}
              style={{ color: atharWebsite && navBg ? "black" : "" }}
            >
              الرئيسية
            </li>
            <li
              onClick={() => handelAbout()}
              style={{ color: atharWebsite && navBg ? "black" : "" }}
            >
              من نحن
            </li>
            <li>
              <NavLink
                to={brand ? "/all-influencers" : "login"}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                style={{ color: atharWebsite && navBg ? "black" : "" }}
              >
                المؤثرين
              </NavLink>
            </li>

            {brand && (
              <li>
                <NavLink
                  to={brand ? "/influencers-logs" : "login"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  style={{ color: atharWebsite && navBg ? "black" : "" }}
                >
                  الزيارات
                </NavLink>
              </li>
            )}
            {/*<li>
              <a
                href="#contact-us"
                alt=""
                onClick={() => {
                  setShowNavBar(false);
                }}
              >
                تواصل معنا
              </a>
              </li>*/}

            {toggleSetting ? (
              <li className="setting-li">
                <NavLink
                  to="/profile-setting/profile-info"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  إعدادت الحساب
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
          {toggleSetting ? (
            <div
              className="profile-user"
              onClick={() => setDropDownToogle(!dropDownToogle)}
            >
              {dropDownToogle && (
                <div
                  className="drop-setting"
                  style={
                    millionWebsite
                      ? { backgroundColor: "rgb(124, 126, 129)" }
                      : takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                      ? { backgroundColor: "rgb(31, 31, 31)" }
                      : {}
                  }
                >
                  <NavLink to="/profile-setting/profile-info">
                    <div className="user-info">
                      <img src={user} alt="" className="profile-img-drop" />
                    </div>
                    <h6 style={{ color: millionWebsite || fadaaWebsite ? "white" : "" }}>
                      {userInfo.name}
                    </h6>
                  </NavLink>
                  <hr
                    className="container"
                    style={
                      takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                        ? { color: "white" }
                        : {}
                    }
                  />
                  <div
                    onClick={() => {
                      console.log("first");
                      setConfirmAdd(true);
                    }}
                    className="logout-div"
                  >
                    <BiLogOut
                      className="logout"
                      style={{
                        color:
                          millionWebsite ||
                          takWebsite ||
                          transWebsite ||
                          sharkWebsite
                            ? "white"
                            : "",
                      }}
                    />
                    <span
                      style={{
                        color:
                          millionWebsite ||
                          takWebsite ||
                          transWebsite ||
                          sharkWebsite || fadaaWebsite
                            ? "white"
                            : "",
                      }}
                    >
                      تسجيل الخروج
                    </span>
                  </div>
                </div>
              )}
              {confirmAdd && (
                <div className="overlay">
                  <div
                    className="popup popup-logout"
                    style={
                      millionWebsite
                        ? { backgroundColor: "#1F1F1F" }
                        : takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                        ? {
                            backgroundColor: "#1F1F1F",
                            border: "1px solid white",
                          }
                        : atharWebsite
                        ? { backgroundColor: "#F1F1F1", borderRadius: "0" }
                        : {}
                    }
                  >
                    <div className="warning-icon">
                      <img src={warringIcon} alt="icon" />
                    </div>

                    <h2 style={{ color: sharkWebsite ? "#FA4B52" : fadaaWebsite ? "white" :fadaaWebsite ? "white" : "" }}>
                      هل انت متأكد
                    </h2>
                    <h5
                      style={{
                        color:
                          takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                            ? "white"
                            : atharWebsite
                            ? "black"
                            : "",
                      }}
                    >
                      من تسجيل الخروج
                    </h5>

                    <div className="actions-btn">
                      <button
                        style={
                          btnDarb
                            ? {
                                background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                                border: "none",
                              }
                            : millionWebsite
                            ? { color: "black" }
                            : takWebsite
                            ? {
                                backgroundColor: "#00F800",
                                borderRadius: "55px",
                                boxShadow:
                                  "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                                color: "black",
                              }
                            : transWebsite
                            ? {
                                borderRadius: "8px",
                                color: "black",
                              }
                            : sharkWebsite
                            ? {
                                backgroundColor: "#FA4B52",
                                color: "white",
                                border: "1px solid #FBFA2D",
                              }
                            : atharWebsite
                            ? {
                                backgroundColor: "#FFFFFF",
                                borderRadius: "55px",
                                boxShadow: "#F15A3A 4px 5px 1px 0px",
                                color: "black",
                              }
                            :fadaaWebsite
                            ? {
                                backgroundColor: "#F5ED0C",
                                color: "black",
                              }
                            : {}
                        }
                        className="btn btn-yes"
                        onClick={() => {
                          logoutFunc();
                        }}
                      >
                        نعم
                      </button>
                      <button
                        style={
                          btnDarb
                            ? {
                                border: "1px solid #11D4D1",
                              }
                            : millionWebsite
                            ? { borderColor: "white", color: "white" }
                            : takWebsite
                            ? {
                                backgroundColor: "transparent",
                                borderRadius: "55px",
                                color: "white",
                                border: "1px solid #FF0593",
                              }
                            : transWebsite
                            ? {
                                borderRadius: "8px",
                                color: "white",
                                border: "1px solid #02C6C6",
                              }
                            : sharkWebsite
                            ? {
                                backgroundColor: "transparent",
                                color: "white",
                                border: "1px solid #FBFA2D",
                              }
                            : atharWebsite
                            ? {
                                backgroundColor: "transparent",
                                borderRadius: "55px",
                                color: "black",
                                border: "1px solid #F15A3A",
                              }
                            :fadaaWebsite
                            ? {
                                background: "transparent",
                                color: "white",
                                border: "1px solid #F5ED0C",
                              }
                            :  {}
                        }
                        className="btn btn-no"
                        onClick={() => setConfirmAdd(false)}
                      >
                        لا
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <img src={user} alt="" className="profile-img" />
            </div>
          ) : (
            <button
              className="btn btn-contact-us"
              onClick={() => navigate("/login")}
              style={
                btnDarb
                  ? {
                      background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                      color: "black",
                    }
                  : millionWebsite
                  ? { backgroundColor: "white", color: "black" }
                  : takWebsite
                  ? {
                      backgroundColor: "#00F800",
                      borderRadius: "55px",
                      boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                      color: "black",
                    }
                  : transWebsite
                  ? {
                      backgroundColor: "#03F8F8",
                      color: "black",
                    }
                  : sharkWebsite
                  ? {
                      backgroundColor: "#FA4B52",
                      color: "white",
                      border: "1px solid #FBFA2D",
                    }
                  : atharWebsite
                  ? {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "55px",
                      boxShadow: "#F15A3A 4px 5px 1px 0px",
                      color: "black",
                      border: "1px solid #00000014",
                    }
                  : fadaaWebsite
                  ? {
                      backgroundColor: "#F5ED0C",
                      color: "black",
                    }
                  : {}
              }
            >
              تسجيل الدخول
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
