import React from 'react';
import "./infuoFooter.css"
import logo from "../../assets/images/infu-logo-footer.png";
import mail from "../../assets/images/sms.png";
import call  from "../../assets/images/calling.png"
import face from "../../assets/images/facebook-infu.png"
import watts from "../../assets/images/whatsapp-infu.png"
import insta from "../../assets/images/instagram-infu.png"
const InfuFooter = () => {


  const handelLinks = (link) => {
   
      window.open(link, "_self");
  
  };
  const handleWhatsAppLink = () => {
    const phoneNumber = '1234567890';  // Replace with the desired phone number
    const message = 'Hello, this is a predefined message!';  // Replace with your message

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappURL;
  };
  const handleFacebookLink = () => {
    const profileUsername = 'profile_username';  // Replace with the username of the Facebook profile
    const message = 'Hello, this is a predefined message!';  // Replace with your message

    const facebookURL = `https://www.facebook.com/messages/t/${profileUsername}?msg=${encodeURIComponent(message)}`;
    window.open(facebookURL, '_blank');
  };
  const handleInstagramLink = () => {
    const profileUsername = 'your_username';  // Replace with your Instagram username

    const instagramURL = `https://www.instagram.com/${profileUsername}/`;
    window.open(instagramURL, '_blank');
  };
  return (
    <div className='infu-footer'>
       <div className="container">
        <div className='foot-head'>
            <div className='infu-logo' onClick={()=>handelLinks("https://infugram.com")}>
              <img src={logo} alt="infu-logo" />
            </div>
            <div className='infu-links'>
                <div >
                    <h3 onClick={()=>handelLinks("https://infugram.com")}>خدماتنا</h3>
                </div>
                <div>
                    <h3 onClick={()=>handelLinks("https://infugram.com")}>شركائنا</h3>
                </div>
                 <div>
                    <div className='infu-conact'>
                       <h3 onClick={()=>handelLinks("https://infugram.com/contact-us")}>تواصل معنا</h3>
                       <h4><img src={call} alt="callInfo" dir='rtl'/><span>229 554  966+</span>  </h4>
                       <h4 ><img src={mail} alt="callInfo" />infogram@gmail.com</h4>

                    </div>
                 </div>
            </div>

        </div>
        
          <div className='infu-social-links'>
            <img onClick={()=>handleWhatsAppLink()} src={watts} alt="infu-watts" />
            <img onClick={()=>handleInstagramLink()} src={insta} alt="infu-intgram" />
            <img onClick={()=>handleFacebookLink()} src={face} alt="infu-facebook" />

          </div>

          <div className='privcy'>
            <h5><span style={{"color":"#E41476"}}>انفوجرام</span> © جميع الحقوق محفوظه</h5>
            <h5>سياسة الخصوصية|الشروط و الاحكام</h5>
          </div>
        

        </div>
    </div>
  )
}

export default InfuFooter