import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LoginPage from "./pages/login-page/LoginPage";
import LoginChoose from "./components/login-choose/LoginChoose";
import "./App.css";
import LoginForm from "./components/login-form/LoginForm";
import BrandRegister from "./pages/brand-register/BrandRegister";
import InfluencerRegister from "./pages/influencer-register/InfluencerRegister";
import Home from "./pages/home/Home";
import Influencer from "./pages/influencer/influencer";
import AllInfluencers from "./pages/all-influencers/AllInfluencers";
import ProfileSetting from "./components/profile-setting/ProfileSetting";
import ProfileInfo from "./components/Profile-info/ProfileInfo";
import ChangePasswordSetting from "./components/change-password/ChangePasswordSetting";
import Logout from "./components/logout/Logout";
import { useEffect, useState } from "react";
import SmouHome from "./smou/smou-pages/smou-home/SmouHome";
import SmouLoginPage from "./smou/smou-pages/smou-login-page/SmouLoginPage";
import NotFound from "./components/notFound/NotFound.jsx";
import QrCheck from "./components/qr-check/QrCheck.js";
import InfluencersLogs from "./pages/influencers-logs/InfluencersLogs.js";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [qrRouting, setQrRouting] = useState(false);
  const [id, setId] = useState("");

  var subdomain = window.location.origin;

  // Define CSS variables based on the subdomain
  var cssVariables = {};

  const DOMAINS = {
    AZZRK: "https://influencer.azzrk.com",
    ATHAR: "https://influencer.atherr.com",
    MARDOD: "https://influencer.marrdoud.com",
    WARAQA: "https://influencer.warrqa.com",
    DARB: "https://influencer.darbplatform.com",
    SMOUE: "https://influencer.sumoue.com",
    SAHM: "https://influencer.sahhm.com",
    KHATWA: "https://influencer.khatwaa.com",
    RAWNQ: "https://influencer.rawnkagency.com",
    BASMA: "https://influencer.basmahagency.com",
    INFO: "https://dashboard.infugram.com",
    MILLION: "https://influencer.one1million.com",
    TAK: "https://influencer.agencytak.com",
    TRANS: "https://influencer.transition-agency.com",
    SHARK: "https://influencer.shark-company.com",
    Roqua: "https://influencer.roaya-marketing.com",
    MSHOR: "https://influencer.mshourr.com",
    FADAA: "https://influencer.fadaaagency.com",
  };

  var smoueWebsite;
  var infuWebsite;
  var transWebsite;
  var sharkWebsite;

  switch (subdomain) {
    case DOMAINS.AZZRK:
      cssVariables.primaryColor = "#022444";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#76C5F5";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#DAF1FF";
      cssVariables.borderColor = "#77C9FF";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "#4D7396";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#022444";
      cssVariables.activePagenation = "#022444";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      break;

    case DOMAINS.MARDOD:
      cssVariables.primaryColor = "#001822";
      cssVariables.secondaryColor = "#16EDB1";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#16EDB1";
      cssVariables.lineColor = "#16EDB1";
      cssVariables.cardColor = "#D5FCF1";
      cssVariables.borderColor = "#16EDB1";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "#001822";
      cssVariables.activePagenation = "#001822";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      break;

    case DOMAINS.SMOUE:
      cssVariables.primaryColor = "#03183D";
      cssVariables.dotColor = "#AD2E34";
      cssVariables.cardColor = "rgba(255, 255, 255, 50%)";
      cssVariables.btnColor = "#fff";
      cssVariables.borderColor = "#fff";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.statsColor = "#fff";
      cssVariables.activeCardColor =
        "linear-gradient(#1C4BA28C,#1117678C, #DB37378C)";
      cssVariables.boxShadow =
        "0 2px 6px -1px rgba(255,255,255,.9), 0 2px 4px -2px rgba(255,255,255,.9)";
      cssVariables.colorFollowersText = "#022444CC";
      cssVariables.componentsListColor = "#fff";
      cssVariables.cardColor = "rgba(255,255,255,43%)";
      cssVariables.activePagenation =
        "linear-gradient(135deg, rgb(162, 32, 32,.9) 0%, rgb(152, 31, 33,.9) 12%, rgb(148, 30, 36,.9) 23%, rgb(139, 29, 37,.9) 32%, rgb(130, 28, 39,.9) 39%, rgb(125, 28, 41,.9) 45%, rgb(115, 28, 42,.9) 49%, rgb(105, 27, 45,.9) 52%, rgb(92, 25, 45,.9) 56%, rgb(82, 25, 48,.9) 60%, rgb(67, 24, 49,.9) 68%, rgb(46, 22, 49,.9) 81%, rgb(3, 23, 53,.9) 100%)";
      smoueWebsite = true;
      infuWebsite = false;
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      break;

    case DOMAINS.DARB:
      cssVariables.primaryColor = "#0C2241";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#0C2241";
      cssVariables.dotColor = "#1CCEDA";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#FFFFFF";
      cssVariables.borderColor = "#21CADE";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "#001822";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor =
        "linear-gradient(to left, #0E2E4A, #2FD8D6)";
      cssVariables.activePagenation =
        "linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      break;

    case DOMAINS.WARAQA:
      cssVariables.primaryColor = "#2C0E42";
      cssVariables.secondaryColor = "#FF6A00";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#FF6A00";
      cssVariables.lineColor = "#FF6A00";
      cssVariables.cardColor = "#FFF4EE";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "#001822";
      cssVariables.borderColor = "#2C0E42";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#2C0E42";
      cssVariables.activePagenation = "#2C0E42";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      break;

    case DOMAINS.ATHAR:
      cssVariables.primaryColor = "#F15A3A";
      cssVariables.secondaryColor = "#D2A030";
      cssVariables.btnColor = "#000000";
      cssVariables.dotColor = "#F15A3A";
      cssVariables.lineColor = "rgba(0, 0, 0, 1)";
      cssVariables.cardColor = "#F1F1F1";
      cssVariables.borderColor = "#B1B1B1";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "black";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#F15A3A";
      cssVariables.activePagenation = "#F15A3A";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      break;

    case DOMAINS.SAHM:
      cssVariables.primaryColor = "#5E384A";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#B3E1A7";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#D9F0D3";
      cssVariables.borderColor = "#31904F";
      cssVariables.statsColor = "#5E384A";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "rgba(2, 36, 68, 0.57)";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#5E384A";
      cssVariables.activePagenation = "#5E384A";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(144, 182, 133, 0.5)";
      break;

    case DOMAINS.KHATWA:
      cssVariables.primaryColor = "#231F20";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#DADC22";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#FFFFFF";
      cssVariables.borderColor = "#FBFA2D";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "rgba(2, 36, 68, 0.57)";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#231F20";
      cssVariables.activePagenation = "#231F20";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      break;

    case DOMAINS.RAWNQ:
      cssVariables.primaryColor = "#054448";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#B7B629";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#FBF7F1";
      cssVariables.borderColor = "#FAD698";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "rgba(2, 36, 68, 0.57)";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#054448";
      cssVariables.activePagenation = "#054448";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      break;

    case DOMAINS.BASMA:
      cssVariables.primaryColor = "#0E1A58";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#B3E1A7";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#0E1A58";
      cssVariables.borderColor = "#243F7A";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.colorFollowersText = "rgba(2, 36, 68, 0.57)";
      smoueWebsite = false;
      cssVariables.activeCardColor = "#2E316F";
      cssVariables.activePagenation = "#0E1A58";
      cssVariables.boxShadow = "0px -1px 15px 0px #2E316F";
      infuWebsite = false;
      break;

    case DOMAINS.INFO:
      cssVariables.primaryColor = "#101B37";
      cssVariables.secondaryColor = "red";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#E41476";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#FFFFFF";
      cssVariables.borderColor = "#101B37";
      cssVariables.outlitChildBackColor = "white";
      smoueWebsite = false;
      cssVariables.activeCardColor = "#101B37";
      cssVariables.activePagenation = "#022444";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      infuWebsite = true;
      break;

    case DOMAINS.MILLION:
      cssVariables.primaryColor = "white";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "black";
      cssVariables.dotColor = "black";
      cssVariables.lineColor = "white";
      cssVariables.cardColor = "#1F1F1F";
      cssVariables.borderColor = "black";
      cssVariables.outlitChildBackColor = "black";
      cssVariables.loginFormTitleColor = "white";
      cssVariables.colorFollowersText = "white";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "white";
      cssVariables.activePagenation = "white";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      infuWebsite = false;
      break;

    case DOMAINS.TAK:
      cssVariables.primaryColor = "#FF0593";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "white";
      cssVariables.dotColor = "#FF0593";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#1F1F1F";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "#1F1F1F";
      cssVariables.loginFormTitleColor = "white";
      cssVariables.colorFollowersText = "white";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#FF0593";
      cssVariables.activePagenation = "#FF0593";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      infuWebsite = false;
      break;

    case DOMAINS.TRANS:
      cssVariables.primaryColor = "#02C6C6";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "black";
      cssVariables.dotColor = "#02C6C6";
      cssVariables.lineColor = "#C2C2C2";
      cssVariables.cardColor = "#1F1F1F";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "";
      cssVariables.loginFormTitleColor = "white";
      cssVariables.colorFollowersText = "white";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#02C6C6";
      cssVariables.activePagenation = "#02C6C6";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      infuWebsite = false;
      transWebsite = true;
      break;

    case DOMAINS.SHARK:
      cssVariables.primaryColor = "#B559E8";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "white";
      cssVariables.dotColor = "#B559E8";
      cssVariables.lineColor = "white";
      cssVariables.cardColor = "#1F1F1F";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "";
      cssVariables.loginFormTitleColor = "white";
      cssVariables.colorFollowersText = "white";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#B559E8";
      cssVariables.activePagenation = "#FA4B52";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(153, 216, 255, 0.5)";
      infuWebsite = false;
      transWebsite = false;
      sharkWebsite = true;
      break;

    case DOMAINS.Roqua:
      cssVariables.primaryColor = "#085D60";
      cssVariables.secondaryColor = "#FFFFFF";
      cssVariables.btnColor = "white";
      cssVariables.dotColor = "#5C7E82";
      cssVariables.lineColor = "white";
      cssVariables.cardColor = "rgba(239, 254, 255, 1)";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.loginFormTitleColor = "#0B7175";
      cssVariables.colorFollowersText = "#085D60";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#085D60";
      cssVariables.activePagenation = "#085D60";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(85, 171, 176, 0.48)";
      infuWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      break;

    case DOMAINS.MSHOR:
      cssVariables.primaryColor = "#FF7222";
      cssVariables.secondaryColor = "#FF7222";
      cssVariables.btnColor = "white";
      cssVariables.dotColor = "#F15A3A";
      cssVariables.lineColor = "white";
      cssVariables.cardColor = "#FFF9EE";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.loginFormTitleColor = "#212121";
      cssVariables.colorFollowersText = "rgba(2, 36, 68, 0.57)";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#FF7222";
      cssVariables.activePagenation = "#FF7222";
      cssVariables.boxShadow = "0px -1px 15px 0px #C3B497";
      infuWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      break;

    case DOMAINS.FADAA:
      cssVariables.primaryColor = "#7444BD";
      cssVariables.secondaryColor = "white";
      cssVariables.btnColor = "white";
      cssVariables.dotColor = "#F5ED0C";
      cssVariables.lineColor = "white";
      cssVariables.cardColor = "rgba(116, 68, 189, 0.1)";
      cssVariables.borderColor = "white";
      cssVariables.outlitChildBackColor = "";
      cssVariables.loginFormTitleColor = "white";
      cssVariables.colorFollowersText = "white";
      cssVariables.componentsListColor = "#9E9E9E";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.activeCardColor = "#7444BD";
      cssVariables.activePagenation = "#7444BD";
      cssVariables.boxShadow = "none";
      infuWebsite = false;
      transWebsite = false;
      sharkWebsite = false;
      break;

    default:
      cssVariables.primaryColor = "#022444";
      cssVariables.secondaryColor = "FFFFFF";
      cssVariables.btnColor = "#FFFFFF";
      cssVariables.dotColor = "#5C7E82";
      cssVariables.lineColor = "#FFFFFF";
      cssVariables.cardColor = "#EFFEFF";
      cssVariables.borderColor = "#77C9FF";
      cssVariables.outlitChildBackColor = "white";
      cssVariables.loginFormTitleColor = "black";
      smoueWebsite = false;
      infuWebsite = false;
      cssVariables.statsColor = "#000";
      cssVariables.boxShadow = "0 5px 10px rgba(0, 0, 0, 0.12)";
      cssVariables.statsColor = "#022444";
      cssVariables.colorFollowersText = "#02244491";
      cssVariables.componentsListColor = "#000";
      cssVariables.activePagenation = "#022444";
      cssVariables.boxShadow = "0px -1px 15px 0px rgba(160, 161, 162, 0.5)";
      sharkWebsite = false;
  }

  // Update the CSS variables
  var root = document.documentElement;

  Object.keys(cssVariables).forEach(function (key) {
    root.style.setProperty("--" + key, cssVariables[key]);
  });

  const checkUserToken = () => {
    const userToken = localStorage.getItem("brand");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      if (
        location.pathname.includes("/all-influencers") ||
        location.pathname.includes("/profile-setting") ||
        location.pathname.includes("/influencers-logs")
      ) {
        navigate("/login");
      }
    } else {
      setIsLoggedIn(true);
    }
    // if (!userToken || userToken === "undefined") {

    //   if (

    //     location.pathname.includes("/check/")
    //   ) {
    //     navigate("/login");
    //     // setQrRouting(true)
    //     console.log(id)
    //   }
    // } else {
    //   setIsLoggedIn(true);
    // }
  };

  useEffect(() => {
    checkUserToken();
  }, [localStorage.getItem("brand")]);

  const loggedInGuard = (element) => {
    // If user is logged in, redirect to home page
    if (isLoggedIn) {
      return <Navigate to="/" replace={true} />;
    }
    // If not logged in, allow access to the provided element
    return element;
  };

  const userToken = localStorage.getItem("brand");
  const determineElementToRender = () => {
    if (smoueWebsite) {
      return <SmouHome />;
    } else if (infuWebsite && userToken) {
      return <AllInfluencers />;
    } else if (infuWebsite) {
      return <LoginPage />;
    } else {
      return <Home />;
    }
  };
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={determineElementToRender()}>
          {infuWebsite && (
            <Route path="" element={loggedInGuard(<LoginForm />)} />
          )}
        </Route>
        {/* <Route path="*" element={determineElementToRender()}>
          {infuWebsite ? (
            <Route path="" element={loggedInGuard(<LoginForm />)} />
          ) : null}
        </Route> */}
        <Route path="*" element={<NotFound />}></Route>

        <Route
          path="/login"
          element={smoueWebsite ? <SmouLoginPage /> : <LoginPage />}
        >
          <Route
            path=""
            element={loggedInGuard(
              <LoginForm
                qrRouting={qrRouting}
                setQrRouting={setQrRouting}
                id={id}
                setId={setId}
              />
            )}
          />
          <Route
            path="create-account"
            element={loggedInGuard(<LoginChoose />)}
          />
        </Route>
        <Route
          path="/create-account/brand"
          element={loggedInGuard(<BrandRegister />)}
        />
        <Route
          path="/create-account/influencer"
          element={loggedInGuard(<InfluencerRegister />)}
        />

        {isLoggedIn && (
          <Route path="/all-influencers" element={<AllInfluencers />} />
        )}
        {isLoggedIn && (
          <Route path="/influencer/:influencer_id" element={<Influencer />} />
        )}
        {isLoggedIn && (
          <Route path="/profile-setting" element={<ProfileSetting />}>
            <Route index element={<ProfileInfo />} />
            <Route
              index
              path="/profile-setting/profile-info"
              element={<ProfileInfo />}
            />
            <Route
              path="/profile-setting/change-password"
              element={<ChangePasswordSetting />}
            />
            <Route path="/profile-setting/logout" element={<Logout />} />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/influencers-logs" element={<InfluencersLogs />} />
        )}
        <Route
          path="/check/:id"
          element={<QrCheck setQrRouting={setQrRouting} setId={setId} />}
        />
      </Routes>
    </div>
  );
}

export default App;
