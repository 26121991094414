import React, { Fragment, useState } from "react";
import "./card.css";

import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import authService from "../../services/auth-services";
import LoadingButton from "../loading-button/LoadingButton";
import somueCard from "../../smou/smou-imgs/smou-card-bg.webp";
import azzrkUser from "../../assets/images/azzrk-user.webp";
import mardodUser from "../../assets/images/mardod-user.webp";
import atharUser from "../../assets/images/athar-user.webp";
import darbUser from "../../assets/images/darb-user.webp";
import warqaUser from "../../assets/images/warqa-user.webp";
import smoueUser from "../../assets/images/smoue-user.webp";
import roquaUser from "../../assets/images/rawnq-user.png";
import mshorUser from "../../assets/images/mshor-user.png";
import sahmUser from "../../assets/images/sahm-user.png";
import face1 from "./../../assets/images/facebook.webp";
import twitter1 from "./../../assets/images/twitter.webp";
import insta1 from "./../../assets/images/insta.webp";
import tiktok1 from "./../../assets/images/tiktok.webp";
import snap1 from "./../../assets/images/snap.webp";
import youtube1 from "./../../assets/images/youtube.webp";
import card__badage from "../../assets/images/card__badge.webp";

const platformIcons = {
  Facebook: face1,
  facebook: face1,
  face: face1,
  Face: face1,
  Twitter: twitter1,
  twitter: twitter1,
  Instagram: insta1,
  instagram: insta1,
  insta: insta1,
  Insta: insta1,
  Tiktok: tiktok1,
  tiktok: tiktok1,
  Snapchat: snap1,
  snap: snap1,
  Snap: snap1,
  snapchat: snap1,
  Youtube: youtube1,
  youtube: youtube1,
};

const DOMAINS = {
  AZZRK: "https://influencer.azzrk.com",
  ATHAR: "https://influencer.atherr.com",
  MARDOD: "https://influencer.marrdoud.com",
  WARAQA: "https://influencer.warrqa.com",
  DARB: "https://influencer.darbplatform.com",
  SMOUE: "https://influencer.sumoue.com",
  INFU: "https://dashboard.infugram.com",
  MILLION: "https://influencer.one1million.com",
  TAK: "https://influencer.agencytak.com",
  SAHM: "https://influencer.sahhm.com",
  TRANS: "https://influencer.transition-agency.com",
  SHARK: "https://influencer.shark-company.com",
  Roqua: "https://influencer.roaya-marketing.com",
  MSHOR: "https://influencer.mshourr.com",
  FADAA: "https://influencer.fadaaagency.com",
  BASMA: "https://influencer.basmahagency.com",
};
var subdomain = window.location.origin;
var defaultPhoto;
var infuPrem;
var millionWebsite;
var takWebsite;
var transWebsite;
var sharkWebsite;
var btnDarb;
var labelSmoue;
var atharWebsite;
var mshorWebsite;
var fadaaWebsite;
var basmaWebsite;

switch (subdomain) {
  case DOMAINS.AZZRK:
    defaultPhoto = azzrkUser;
    btnDarb = false;
    labelSmoue = false;
    break;

  case DOMAINS.MARDOD:
    defaultPhoto = mardodUser;
    btnDarb = false;
    labelSmoue = false;
    break;
  case DOMAINS.SMOUE:
    defaultPhoto = smoueUser;
    btnDarb = false;
    labelSmoue = true;
    break;

  case DOMAINS.DARB:
    defaultPhoto = darbUser;
    btnDarb = true;
    labelSmoue = false;
    break;

  case DOMAINS.WARAQA:
    defaultPhoto = warqaUser;
    btnDarb = false;
    labelSmoue = false;
    break;

  case DOMAINS.ATHAR:
    defaultPhoto = atharUser;
    btnDarb = false;
    labelSmoue = false;
    atharWebsite = true;
    break;

  case DOMAINS.INFU:
    infuPrem = true;
    defaultPhoto = atharUser;
    break;

  case DOMAINS.MILLION:
    defaultPhoto = azzrkUser;
    millionWebsite = true;
    break;

  case DOMAINS.TAK:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    takWebsite = true;
    break;

  case DOMAINS.BASMA:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    takWebsite = false;
    basmaWebsite = true;
    break;

  case DOMAINS.SAHM:
    defaultPhoto = sahmUser;
    millionWebsite = false;
    takWebsite = false;
    break;

  case DOMAINS.TRANS:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    takWebsite = false;
    transWebsite = true;
    break;

  case DOMAINS.SHARK:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = true;
    break;

  case DOMAINS.Roqua:
    defaultPhoto = roquaUser;
    millionWebsite = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    break;

  case DOMAINS.MSHOR:
    defaultPhoto = mshorUser;
    millionWebsite = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    break;

  case DOMAINS.FADAA:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    takWebsite = false;
    transWebsite = false;
    sharkWebsite = false;
    fadaaWebsite = true;
    break;

  default:
    defaultPhoto = azzrkUser;
    millionWebsite = false;
    infuPrem = false;
    takWebsite = false;
    break;
}

const Card = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAllInfluencersPage =
    location.pathname.includes("all-influencers") || infuPrem;
  const { topInfluencers } = props;
  const [toogleOverlay, setToogleOverlay] = useState(false);
  const [addedId, setAddedId] = useState("");
  const [influencerInfo, setInfluencerInfo] = useState();
  const [influrncerId, setInfluencerId] = useState("");
  const brand = JSON.parse(localStorage.getItem("brand"));

  const renderSocialMediaIcons = () => {
    if (isAllInfluencersPage) {
      console.log(topInfluencers.influencer.social_media);
      return topInfluencers.influencer.social_media.map((platform, index) => {
        if (platform.platform && platformIcons[platform.platform]) {
          return (
            <div className="icon" key={index}>
              <NavLink
                to={platform.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={platformIcons[platform.platform]}
                  alt={platform.platform}
                />
              </NavLink>
            </div>
          );
        }
        return null;
      });
    } else {
      return topInfluencers.social_media.map((platform, index) => {
        if (platform.platform && platformIcons[platform.platform]) {
          return (
            <div className="icon" key={index}>
              <NavLink
                to={platform.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={platformIcons[platform.platform]}
                  alt={platform.platform}
                />
              </NavLink>
            </div>
          );
        }
        return null;
      });
    }
  };

  const calculateTotalFollowers = () => {
    let totalFollowers = 0;
    if (isAllInfluencersPage) {
      for (const platform of topInfluencers.influencer.social_media) {
        totalFollowers += platform.followers || 0;
      }
    } else {
      for (const platform of topInfluencers.social_media) {
        totalFollowers += platform.followers || 0;
      }
    }

    return totalFollowers;
  };

  const formatFollowers = (followers) => {
    if (followers >= 1000000000) {
      return `${(followers / 1000000000).toFixed(0)} مليار`;
    } else if (followers >= 1000000) {
      return `${(followers / 1000000).toFixed(0)} مليون`;
    } else if (followers >= 1000) {
      return `${(followers / 1000).toFixed(0)} الاف`;
    } else {
      return followers.toString();
    }
  };

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);

  const setConfirmAddFunc = (index) => {
    setConfirmAdd(true);
    setAddedId(index);
  };
  const addInfluencers = (addedId) => {
    try {
      setLoadingLogin(true);
      authService.addInfluencer(addedId).then(
        (response) => {
          setConfirmAdd(false);
          setLoadingLogin(false);
          setToogleOverlay(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "تم الاضافة بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          props.getTopInfluencers();
        },
        (error) => {
          setLoadingLogin(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.non_field_errors &&
            error.response.data.non_field_errors[0] ===
              "Maximum influencers reached"
          ) {
            setToogleOverlay(false);
            Swal.fire({
              position: "center",
              icon: "error",
              title: "لقد وصلت للحد الأقصي من عدد المؤثرين",
              showConfirmButton: false,
              timer: 1500,
            });
            setConfirmAdd(false);
          }
        }
      );
    } catch (err) {}
  };

  const deleteInfluencers = (deletedId) => {
    try {
      setLoadingLogin(true);
      setConfirmAdd(false);

      authService.deleteInfluencer(deletedId).then(
        (response) => {
          setLoadingLogin(false);
          setToogleOverlay(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "تم الحذف بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          props.getTopInfluencers();
        },

        (error) => {
          setLoadingLogin(false);
        }
      );
    } catch (err) {}
  };

  const handleAction = () => {
    if (brand) {
      const influencerIdForInfo = isAllInfluencersPage
        ? topInfluencers.influencer.id
        : topInfluencers.id;
      setToogleOverlay(true);
      setInfluencerId(influencerIdForInfo);
      setConfirmAddFunc(influencerIdForInfo);
      authService
        .influencerInformation(influencerIdForInfo)
        .then((response) => {
          setInfluencerInfo(response);
        });
    } else {
      navigate("/login");
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Fragment>
      <div
        className={
          labelSmoue
            ? "card p-4 cardSmoue"
            : fadaaWebsite
            ? "card p-4 card-websites fadaaCard"
            : basmaWebsite
            ? "card p-4 card-websites basmaCard"
            : "card container p-4 card-websites "
        }
        style={
          isHovered && btnDarb
            ? {
                background: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                color: "white",
              }
            : isHovered && millionWebsite
            ? {
                background: `linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%)`,
                color: "white",
              }
            : isHovered && labelSmoue
            ? {
                backgroundImage: `url(${somueCard})`,
                backgroundPosition: "center",
                transform: "scale(1.1)",
              }
            : {}
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {topInfluencers.accepted ? (
          <div className="card-padage">
            <img src={card__badage} alt="" />
          </div>
        ) : topInfluencers.is_accepted ? (
          <div className="card-padage">
            <img src={card__badage} alt="" />
          </div>
        ) : (
          ""
        )}
        <div className="d-flex flex-column gap-4">
          <div>
            <div
              className="person d-flex gap-4 align-items-center"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                if (isAllInfluencersPage) {
                  if (brand) {
                    navigate(`/influencer/${topInfluencers.influencer.id}`);
                  } else {
                    navigate("/login");
                  }
                } else {
                  if (brand) {
                    navigate(`/influencer/${topInfluencers.id}`);
                  } else {
                    navigate("/login");
                  }
                }
              }}
            >
              <div className="image">
                <img
                  src={
                    isAllInfluencersPage && topInfluencers.influencer.photo
                      ? topInfluencers.influencer.photo
                      : topInfluencers.photo
                      ? topInfluencers.photo
                      : defaultPhoto
                  }
                  alt=""
                  className="influencer-photo"
                />
              </div>
              <div className="text">
                <h1
                  className={
                    atharWebsite ? "foll-numbs foll-numbs-athar" : "foll-numbs"
                  }
                  style={{
                    color: btnDarb
                      ? "#03183D"
                      : takWebsite ||
                        transWebsite ||
                        sharkWebsite ||
                        fadaaWebsite ||
                        basmaWebsite
                      ? "white"
                      : atharWebsite
                      ? "black"
                      : "",
                  }}
                >
                  {isAllInfluencersPage
                    ? topInfluencers.influencer.name
                    : topInfluencers.name}
                </h1>
                <p
                  className="foll-numbs"
                  style={{
                    color: btnDarb
                      ? "#03183D"
                      : millionWebsite
                      ? "#FF0593"
                      : sharkWebsite
                      ? "#FA4B52"
                      : fadaaWebsite
                      ? "#FA4B52"
                      : basmaWebsite
                      ? "rgba(255, 255, 255, 0.72)"
                      : "",
                  }}
                >
                  مؤثر
                </p>
              </div>
            </div>
          </div>
          <div
            className={
              atharWebsite
                ? "followers-athar followers d-flex align-items-center gap-3"
                : "followers d-flex align-items-center gap-3"
            }
          >
            <p
              style={{
                color: btnDarb
                  ? "rgb(3, 24, 61)"
                  : labelSmoue
                  ? "rgba(255,255,255,80%)"
                  : millionWebsite || takWebsite || transWebsite
                  ? "#00F800"
                  : sharkWebsite
                  ? "#FBE021"
                  : atharWebsite
                  ? "#F15A3A"
                  : fadaaWebsite
                  ? "#FBE021"
                  : "",
              }}
            >
              عدد المتابعين:
            </p>
            <span
              style={{
                color: btnDarb
                  ? "rgb(3, 24, 61)"
                  : labelSmoue
                  ? "rgba(255,255,255,80%)"
                  : takWebsite || transWebsite || sharkWebsite || basmaWebsite
                  ? "white"
                  : atharWebsite
                  ? "black"
                  : "",
              }}
            >
              {formatFollowers(calculateTotalFollowers())}
            </span>
          </div>
          <div className="icons d-flex flex-wrap align-items-center">
            {renderSocialMediaIcons()}
          </div>
          {isAllInfluencersPage && topInfluencers.influencer.categories ? (
            <div className="into">
              {topInfluencers.influencer.categories.map((item, index) => {
                const isCardHovered = hoveredIndex === index;
                return (
                  <div
                    key={index}
                    className="item"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    title={`${item}`}
                    style={{
                      background:
                        btnDarb && isCardHovered
                          ? `white`
                          : btnDarb
                          ? "linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)"
                          : labelSmoue
                          ? "rgba(255, 255, 255, 50%)"
                          : takWebsite || transWebsite || sharkWebsite
                          ? "white"
                          : atharWebsite
                          ? "#F1F1F1"
                          : "var(--primaryColor)",
                      color:
                        labelSmoue && isCardHovered
                          ? "white"
                          : btnDarb
                          ? "#022444"
                          : millionWebsite ||
                            takWebsite ||
                            transWebsite ||
                            sharkWebsite ||
                            atharWebsite
                          ? "black"
                          : "white",
                      border: atharWebsite
                        ? "1px solid #F15A3A"
                        : basmaWebsite
                        ? "1px solid white"
                        : "",
                      borderRadius: takWebsite ? "55px" : "",
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          ) : (
            topInfluencers && (
              <div className="into">
                {topInfluencers.categories.map((item, index) => {
                  const isCardHovered = hoveredIndex === index;
                  return (
                    <div
                      className="item"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      title={`${item}`}
                      style={{
                        background:
                          btnDarb && isCardHovered
                            ? `white`
                            : btnDarb
                            ? "linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)"
                            : labelSmoue
                            ? "rgba(255, 255, 255, 50%)"
                            : takWebsite || transWebsite || sharkWebsite
                            ? "white"
                            : atharWebsite
                            ? "#F1F1F1"
                            : "var(--primaryColor)",
                        color:
                          labelSmoue && isCardHovered
                            ? "white"
                            : btnDarb
                            ? "#022444"
                            : millionWebsite ||
                              takWebsite ||
                              transWebsite ||
                              sharkWebsite ||
                              atharWebsite
                            ? "black"
                            : "white",
                        border: atharWebsite
                          ? "1px solid #F15A3A"
                          : basmaWebsite
                          ? "1px solid white"
                          : "",
                        borderRadius: takWebsite ? "55px" : "",
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            )
          )}
        </div>
        <div className="btn-add-div">
          <button
            className="btn btn-add"
            onClick={() => handleAction()}
            style={{
              color:
                millionWebsite || takWebsite || transWebsite || sharkWebsite
                  ? "black"
                  : "",
              borderRadius: takWebsite ? "55px" : "",
            }}
          >
            {isAllInfluencersPage
              ? topInfluencers.accepted
                ? "حذف"
                : "اضافة"
              : topInfluencers.is_accepted
              ? "حذف"
              : "اضافة"}
          </button>
        </div>
      </div>
      {toogleOverlay && (
        <div className="add-overlay">
          <div
            className="add-popup popup-add"
            style={
              labelSmoue
                ? {
                    backgroundColor: "rgba(255,255,255,.84)",
                  }
                : millionWebsite
                ? { backgroundColor: "#1F1F1F" }
                : takWebsite || transWebsite || fadaaWebsite
                ? { backgroundColor: "#1F1F1F", border: "1px solid white" }
                : sharkWebsite
                ? { backgroundColor: "#1F1F1F", border: "1px solid #B1B1B1" }
                : atharWebsite
                ? { backgroundColor: "#F1F1F1", borderRadius: "0" }
                : {}
            }
          >
            <h2
              style={{
                color: sharkWebsite ? "#FA4B52" : fadaaWebsite ? "white" : "",
              }}
            >
              هل انت متأكد
            </h2>
            <h5
              style={{
                color:
                  takWebsite || transWebsite || sharkWebsite || fadaaWebsite
                    ? "white"
                    : atharWebsite
                    ? "black"
                    : "",
              }}
            >
              من{" "}
              {isAllInfluencersPage
                ? topInfluencers.accepted
                  ? "حذف"
                  : "اضافة"
                : topInfluencers.is_accepted
                ? "حذف"
                : "اضافة"}{" "}
            </h5>
            <div className="add-influencer-info">
              <div className="influencer-info-img">
                <img
                  src={
                    influencerInfo?.influencer?.photo
                      ? influencerInfo.influencer.photo
                      : defaultPhoto
                  }
                  alt="icon"
                  className="influencer-info-photo"
                />
              </div>
              <div className="add-influencer-details">
                <h4
                  style={
                    millionWebsite ||
                    takWebsite ||
                    transWebsite ||
                    sharkWebsite ||
                    fadaaWebsite
                      ? { color: "white" }
                      : {}
                  }
                >
                  {influencerInfo?.influencer.name}
                </h4>
                <h6
                  style={
                    millionWebsite ||
                    takWebsite ||
                    transWebsite ||
                    sharkWebsite ||
                    fadaaWebsite
                      ? { color: "white" }
                      : {}
                  }
                >
                  {formatFollowers(calculateTotalFollowers())}
                </h6>
              </div>
            </div>
            <div className="actions-btn">
              <button
                style={
                  btnDarb
                    ? {
                        background: `linear-gradient(218.3deg, #25C7E2 7.46%, #0CD7CD 74.03%, #F3F3F3 107.31%)`,
                        color: "black",
                        border: "none",
                        borderRadius: "10px",
                      }
                    : labelSmoue
                    ? {
                        background: `linear-gradient(to right,#DB3737, transparent 65%), linear-gradient(to left, #002762, transparent 215%)`,
                        color: "white",
                        border: "none",
                        borderRadius: "12px",
                      }
                    : millionWebsite
                    ? { color: "black" }
                    : takWebsite
                    ? {
                        backgroundColor: "#00F800",
                        borderRadius: "55px",
                        boxShadow: "rgba(255, 5, 147, 0.75) 4px 5px 1px 0px",
                        color: "black",
                      }
                    : transWebsite
                    ? {
                        borderRadius: "8px",
                        color: "black",
                      }
                    : sharkWebsite
                    ? {
                        backgroundColor: "#FA4B52",
                        color: "white",
                        border: "1px solid #FBFA2D",
                      }
                    : atharWebsite
                    ? {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "55px",
                        boxShadow: "#F15A3A 4px 5px 1px 0px",
                        color: "black",
                      }
                    : fadaaWebsite
                    ? {
                        backgroundColor: "#F5ED0C",
                        color: "black",
                      }
                    : {}
                }
                className="btn btn-yes"
                onClick={() => {
                  if (isAllInfluencersPage) {
                    if (topInfluencers.accepted) {
                      deleteInfluencers(addedId);
                    } else {
                      addInfluencers(addedId);
                    }
                  } else {
                    if (topInfluencers.is_accepted) {
                      deleteInfluencers(addedId);
                    } else {
                      addInfluencers(addedId);
                    }
                  }
                }}
              >
                {" "}
                {loadingLogin ? <LoadingButton /> : "نعم"}
              </button>
              <button
                style={
                  btnDarb
                    ? {
                        border: "1px solid #11D4D1",
                        background: `white`,
                        color: "black",
                        border: "1px solid #21CADE",
                        borderRadius: "10px",
                      }
                    : labelSmoue
                    ? {
                        background: "transparent",
                        color: "black",
                        border: "1px solid #8d3e3e",
                        borderImage: `linear-gradient(to left, rgb(33, 202, 222), transparent 90%), linear-gradient(to right, rgb(17, 212, 209), transparent 90%)`,
                        borderRadius: "12px",
                        borderImageSlice: "1",
                      }
                    : millionWebsite
                    ? {
                        backgroundColor: "#1F1F1F",
                        color: "white",
                        borderColor: "white",
                      }
                    : takWebsite
                    ? {
                        backgroundColor: "transparent",
                        borderRadius: "55px",
                        color: "white",
                        border: "1px solid rgba(255, 5, 147, 0.75)",
                      }
                    : transWebsite
                    ? {
                        borderRadius: "8px",
                        color: "white",
                        border: "1px solid #02C6C6",
                      }
                    : sharkWebsite
                    ? {
                        backgroundColor: "transparent",
                        color: "white",
                        border: "1px solid #FBFA2D",
                      }
                    : atharWebsite
                    ? {
                        backgroundColor: "transparent",
                        borderRadius: "55px",
                        color: "black",
                        border: "1px solid #F15A3A",
                      }
                    : fadaaWebsite
                    ? {
                        background: "transparent",
                        color: "white",
                        border: "1px solid #F5ED0C",
                      }
                    : {}
                }
                className="btn btn-no"
                onClick={() => setToogleOverlay(false)}
              >
                لا
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Card;
